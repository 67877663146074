import React from "react";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Footer from "../../components/global/Footer";
import manofactura from "../../css/assets/industrias/manofactura.jpg";
import { manufacturingData } from "../../data/manufacturing";
import Header from "../Header";
import { useSelector } from "react-redux";

export const Manufactura = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "/",
			text: "Industrias",
		},
		{
			href: "",
			text: "Manufactura",
		},
	];
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-center font-body mti-container">
				<img className="max-h-[800px]" src={manofactura} alt="Your Company" />
				<div className="absolute flex items-start flex-col lg:mt-[400px] md:mt-[280px] mt-[120px] sm:px-8 px-4">
					<div className="text-[#F7F7F7] tracking-[1px] uppercase ">
						{language !== "es-mx" ? "industries" : "industrias"}
					</div>
					<div className="text-[#F7F7F7] md:text-4xl text-2xl flex items-end flex-col tracking-[2.5px]">
						<span> {language !== "es-mx" ? "Manufacture" : "Manofactura"}</span>
					</div>
				</div>
			</div>
			<div className="font-body lg:mt-16 mt-8 container p-4">
				{manufacturingData &&
					manufacturingData.values.map((item, index) => (
						<div className="grid md:grid-cols-3 md:mb-24 mb-12 items-center lg:gap-10 gap-5 justify-start">
							{item.imgOnLeft ? (
								<>
									<div className="hidden md:block">
										<img src={item.src} alt="" />
									</div>
									<div className="lg:col-span-2">
										<p className="lg:text-4xl text-2xl font-bold md:mb-0 mb-4">
											{language !== "es-mx" ? item.titleEng : item.title}
										</p>
										<p className="lg:text-lg text-base">
											{" "}
											{language !== "es-mx" ? item.contentEng : item.content}
										</p>
									</div>
									<div className="block md:hidden">
										<img src={item.src} alt="" />
									</div>
								</>
							) : (
								<>
									<div className="lg:col-span-2">
										<p className="lg:text-4xl text-2xl font-bold md:mb-0 mb-4">
											{language !== "es-mx" ? item.titleEng : item.title}
										</p>
										<p className="lg:text-lg text-base">
											{" "}
											{language !== "es-mx" ? item.contentEng : item.content}
										</p>
									</div>
									<div>
										<img src={item.src} alt="" />
									</div>
								</>
							)}
						</div>
					))}
			</div>
			<Footer />
		</>
	);
};
