import React from "react";
import mtiLogo from "../../css/assets/mti-consulting-logo.png";
import fbLogo from "../../css/assets/icons/facebook.png";
import waLogo from "../../css/assets/icons/whatsapp.png";
import liLogo from "../../css/assets/icons/linkedin.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<div className="bg-[#000000] mti-footer">
			<div className="mx-auto px-16 sm:px-6 lg:px-8 h-26 flex md:justify-between md:flex-row flex-col">
				<div className="lg:px-16 py-4 lg:py-8">
					<Link to="/">
						<img
							className="block h-[50px] w-auto"
							src={mtiLogo}
							alt="Your Company"
						/>
					</Link>
				</div>
				<div className="lg:px-16 py-4 lg:py-8 flex flex-row gap-8 items-center justify-center mb-8">
					<a href="https://www.facebook.com/mticonsultingservices/">
						<i
							class="fa-brands fa-facebook block fa-2xl w-auto"
							style={{ color: "#ffffff" }}
						></i>
						{/* <img
							className="block h-[35px] w-auto"
							src={fbLogo}
							alt="Your Company"
						/> */}
					</a>
					<a href="https://www.linkedin.com/company/74132801/admin/?feedType=following ">
						<i
							class="fa-brands fa-linkedin-in block fa-2xl w-auto"
							style={{ color: "#ffffff" }}
						></i>
						{/* <img
							className="block h-[35px] w-auto "
							src={liLogo}
							alt="Your Company"
						/> */}
					</a>
					<a href="https://www.instagram.com/mticonsultingservices/">
						<i
							class="fa-brands fa-instagram block fa-2xl w-auto"
							style={{ color: "#ffffff" }}
						></i>
					</a>
				</div>
			</div>
			<div className="copy-right text-[#F7f7f7] font-body w-full text-center pb-[25px] px-6">
				{language !== "es-mx"
					? "Copyright © 2020 MTi Consulting - All Rights Reserved."
					: "Copyright © 2020 MTi Consulting - Todos los derechos reservados."}
			</div>
		</div>
	);
};

export default Footer;
