import { Outlet, Link } from "react-router-dom";
import mtiLogo from "../css/assets/mti-consulting-logo.png";
import "../css/pages/Layout.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../store/languages/languageChange";

const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [showLanguage, setShowLanguage] = useState(false);

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [language, setLanguage] = useState("");

	const dispatch = useDispatch();

	const handleResize = () => {
		if (window.innerWidth > 991) {
			setShowMobileMenu(false);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize, false);

		//
		//
		if (localStorage.getItem("language") === null) {
			localStorage.setItem("language", "en-us");
			setLanguage("en-us");
		} else {
			setLanguage(localStorage.getItem("language"));
			dispatch(changeLanguage(localStorage.getItem("language")));
		}
		// if (localStorage.getItem("language")) {
		// }
	}, []);

	return (
		<>
			<div className="header-wrapper">
				<div>
					<Link to="/">
						<img className="header-logo" src={mtiLogo} alt="Your Company" />
					</Link>
				</div>
				<div className="hidden lg:block">
					<nav className="header-nav">
						<ul className="header-list">
							<li
								onClick={() => setShowLanguage(!showLanguage)}
								className="cursor-pointer"
							>
								{language} <i class="fa-solid fa-earth-americas"></i>
								{showLanguage && (
									<ul className="language-list">
										<li
											onClick={() => {
												localStorage.setItem("language", "es-mx");
												setLanguage("es-mx");
												dispatch(changeLanguage("es-mx"));
											}}
										>
											ES-MX
										</li>
										<li
											onClick={() => {
												localStorage.setItem("language", "en-us");
												setLanguage("en-us");
												dispatch(changeLanguage("en-us"));
											}}
										>
											EN-US
										</li>
									</ul>
								)}
							</li>
							<li>
								<Link to="/">{language !== "es-mx" ? "Home" : "Inicio"}</Link>
							</li>
							<li>
								<Link to="/productos-sap">
									{language !== "es-mx" ? "Products" : "Productos"}
								</Link>
							</li>
							<li
								className="cursor-pointer"
								onClick={() => setShowMenu(!showMenu)}
							>
								<span className="mr-[10px]">
									{language !== "es-mx" ? "Industries" : "Industrias"}
								</span>
								{showMenu ? (
									<i class="fa-solid fa-angle-up"></i>
								) : (
									<i class="fa-sharp fa-solid fa-angle-down"></i>
								)}
								{showMenu && (
									<ul className="dropdown-list">
										<li>
											<Link to="/industrias/alimentaria">
												{language !== "es-mx" ? "Food" : "Alimentaria"}
											</Link>
										</li>
										<li>
											<Link to="/industrias/automotriz">
												{language !== "es-mx" ? "Automotive" : "Automotriz"}
											</Link>
										</li>
										<li>
											<Link to="/industrias/educacion">
												{language !== "es-mx" ? "Education" : "Educacion"}
											</Link>
										</li>
										<li>
											<Link to="/industrias/hotelera">
												{language !== "es-mx" ? "Hotelier" : "Hotelera"}
											</Link>
										</li>
										<li>
											<Link to="/industrias/manufactura">
												{language !== "es-mx" ? "Manufacture" : "Manufactura"}
											</Link>
										</li>
									</ul>
								)}
							</li>
							<li>
								<Link to="/servicios">
									{language !== "es-mx" ? "Services" : "Servicios"}
								</Link>
							</li>
							<li>
								<Link to="/contacto">
									{language !== "es-mx" ? "Contact" : "Contacto"}
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				<div className="block lg:hidden menu-mobile">
					<i
						class="fa-solid fa-bars fa-lg"
						onClick={() => {
							setShowMobileMenu(!showMobileMenu);
						}}
					></i>
				</div>
			</div>
			<div>
				<nav
					className={
						showMobileMenu ? "menu-mobile-nav-open" : "menu-mobile-nav-close"
					}
				>
					<ul
						className={
							showMobileMenu
								? "menu-mobile-list-open"
								: "menu-mobile-list-close"
						}
					>
						<li onClick={() => setShowMobileMenu(false)}>
							<Link to="/">{language !== "es-mx" ? "Home" : "Inicio"}</Link>
						</li>
						<li onClick={() => setShowMobileMenu(false)}>
							<Link to="/productos-sap">
								{language !== "es-mx" ? "Products" : "Productos"}
							</Link>
						</li>
						<li
							className="cursor-pointer"
							onClick={() => setShowMenu(!showMenu)}
						>
							<span className="mr-[10px]">
								{language !== "es-mx" ? "Industries" : "Industrias"}
							</span>
							{showMenu ? (
								<i class="fa-solid fa-angle-up"></i>
							) : (
								<i class="fa-sharp fa-solid fa-angle-down"></i>
							)}
							{showMenu && (
								<ul className="dropdown-mobile-list">
									<li onClick={() => setShowMobileMenu(false)}>
										<Link to="/industrias/alimentaria">
											{language !== "es-mx" ? "Food" : "Alimentaria"}
										</Link>
									</li>
									<li onClick={() => setShowMobileMenu(false)}>
										<Link to="/industrias/automotriz">
											{language !== "es-mx" ? "Automotive" : "Automotriz"}
										</Link>
									</li>
									<li onClick={() => setShowMobileMenu(false)}>
										<Link to="/industrias/educacion">
											{language !== "es-mx" ? "Education" : "Educacion"}
										</Link>
									</li>
									<li onClick={() => setShowMobileMenu(false)}>
										<Link to="/industrias/hotelera">
											{language !== "es-mx" ? "Hotelier" : "Hotelera"}
										</Link>
									</li>
									<li onClick={() => setShowMobileMenu(false)}>
										<Link to="/industrias/manufactura">
											{language !== "es-mx" ? "Manufacture" : "Manofactura"}
										</Link>
									</li>
								</ul>
							)}
						</li>
						<li onClick={() => setShowMobileMenu(false)}>
							<Link to="/servicios">
								{language !== "es-mx" ? "Services" : "Servicios"}
							</Link>
						</li>
						<li onClick={() => setShowMobileMenu(false)}>
							<Link to="/contacto">
								{language !== "es-mx" ? "Contact" : "Contacto"}
							</Link>
						</li>
						<li
							onClick={() => setShowLanguage(!showLanguage)}
							className="cursor-pointer"
						>
							{language} <i class="fa-solid fa-earth-americas"></i>
							{showLanguage && (
								<ul className="dropdown-mobile-list">
									<li
										onClick={() => {
											localStorage.setItem("language", "es-mx");
											setLanguage("es-mx");
											dispatch(changeLanguage("es-mx"));
											setShowMobileMenu(false);
										}}
									>
										ES-MX
									</li>
									<li
										onClick={() => {
											localStorage.setItem("language", "en-us");
											setLanguage("en-us");
											dispatch(changeLanguage("en-us"));
											setShowMobileMenu(false);
										}}
									>
										EN-US
									</li>
								</ul>
							)}
						</li>
					</ul>
				</nav>
			</div>
			<Outlet />
		</>
	);
};

export default Header;
