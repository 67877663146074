import React from "react";
import Footer from "../../components/global/Footer";
import HeroSapPage from "../../components/global/HeroSapPage";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const SapAriba = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
			textEng: "Home",
		},
		{
			href: "/productos-sap",
			text: "Productos SAP",
			textEng: "SAP Products",
		},
		{
			href: "",
			text: "SAP Ariba",
			textEng: "SAP Ariba",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<div>
			<Header />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<HeroSapPage />
			<div className="container mti-container">
				<div className="font-body px-6">
					<div className="text-4xl lg:text-6xl tracking-[1px] text-[#3991d2] py-6 lg:py-10">
						{/* {title} */}
						SAP Ariba
					</div>
					<div
						className={
							"flex flex-col text-base lg:text-lg tracking-[1px] mb-16"
						}
					>
						<div className="w-auto space-y-8 lg:space-y-10">
							{language !== "es-mx" ? (
								<>
									<p>
										It is an online invoice processing and expense management
										platform, owned by SAP. Ariba helps companies streamline and
										automate their purchasing, expense management, and invoice
										processing processes, saving time and reducing costs. Ariba
										also offers a wide range of procurement solutions, including
										contracts, category management, sourcing, and supplier
										relationship management.
									</p>
									<p>
										With Ariba, companies can improve transparency and
										efficiency in their purchasing and spending operations,
										while strengthening their supplier relationships and
										improving visibility and control over their spending.
									</p>
									<p>
										<b>Why does my company need Ariba? </b>
									</p>
									<p>
										Your company may need Ariba if it is looking to improve
										efficiency and transparency in its purchasing operations,
										increase collaboration with its suppliers, and reduce
										indirect costs associated with manual and uncoordinated
										procurement. With Ariba, companies can automate their
										purchasing processes, consolidate their suppliers, and gain
										real-time visibility into their spending.
									</p>
								</>
							) : (
								<>
									<p>
										Es una plataforma de gestión de gastos y procesamiento de
										facturas en línea, propiedad de SAP. Ariba ayuda a las
										empresas a optimizar y automatizar sus procesos de compras,
										gestión de gastos y procesamiento de facturas, ahorrando
										tiempo y reduciendo costos. Ariba también ofrece una amplia
										gama de soluciones de procurement, como contratos, gestión
										de categorías, sourcing y gestión de relaciones con
										proveedores.
									</p>
									<p>
										Con Ariba, las empresas pueden mejorar la transparencia y
										eficiencia en sus operaciones de compras y gastos, al mismo
										tiempo que fortalecen sus relaciones con proveedores y
										mejoran la visibilidad y control sobre sus gastos.
									</p>
									<p>
										<b>¿Por qué mi empresa necesita Ariba?</b>
									</p>
									<p>
										Tu empresa puede necesitar Ariba si busca mejorar la
										eficiencia y la transparencia en sus operaciones de compra,
										aumentar la colaboración con sus proveedores y reducir los
										costos indirectos asociados con las adquisiciones manuales y
										descoordinadas. Con Ariba, las empresas pueden automatizar
										sus procesos de compra, consolidar sus proveedores y obtener
										una visibilidad en tiempo real sobre sus gastos.
									</p>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="mb-16">
					<div className="lg:w-[966px] lg:h-[544px] m-auto">
						<iframe
							class="w-full aspect-video"
							src="https://www.youtube.com/embed/k_WjzLqI0ho"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
