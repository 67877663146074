import React from "react";
import Footer from "../../components/global/Footer";
import HeroSapPage from "../../components/global/HeroSapPage";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";

export const SuccessFactors = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
			textEng: "Home",
		},
		{
			href: "/productos-sap",
			text: "Productos SAP",
			textEng: "SAP Products",
		},
		{
			href: "",
			text: "SuccessFactors",
			textEng: "SuccessFactors",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<div>
			<Header />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<HeroSapPage />
			<div className="container mti-container">
				<div className="font-body px-6">
					<div className="text-4xl lg:text-6xl tracking-[1px] text-[#3991d2] py-6 lg:py-10">
						{/* {title} */}
						SuccessFactors
					</div>
					<div
						className={
							"flex flex-col text-base lg:text-lg tracking-[1px] mb-16"
						}
					>
						<div className="w-auto space-y-8 lg:space-y-10">
							{language !== "es-mx" ? (
								<>
									<p>
										It is a cloud talent management software platform developed
										by SAP. Offers a wide range of Human Resources (HR) and
										performance management solutions, including job compensation
										and benefits management, goals and performance management,
										training and development, succession management, and
										diversity management and inclusion.
									</p>
									<p>
										SuccessFactors enables companies to streamline and automate
										their HR processes to improve employee performance and
										productivity.
									</p>
									<p>
										<b>Why does my company need SuccessFactors? </b>
									</p>
									<p>
										The platform offers various talent management and human
										resources solutions:
									</p>
								</>
							) : (
								<>
									<p>
										Es una plataforma de software de gestión de talento en la
										nube desarrollada por SAP. Ofrece una amplia gama de
										soluciones de Recursos Humanos (RRHH) y gestión del
										desempeño, incluyendo la gestión de compensaciones y
										beneficripciones de puestos, la gestión de objetivos y
										desempeño, la formación y el desarrollo, la gestión de
										sucesiones y la gestión de la diversidad y la inclusión.
									</p>
									<p>
										SuccessFactors permite a las empresas optimizar y
										automatizar sus procesos de recursos humanos para mejorar el
										desempeño y la productividad de los empleados.
									</p>
									<p>
										<b>¿Por qué mi empresa necesita Success Factors?</b>
									</p>
									<p>
										La plataforma ofrece diversas soluciones de gestión de
										talentos y recursos humanos:
									</p>
								</>
							)}
							<ul className="w-auto space-y-4 lg:space-y-6">
								{language !== "es-mx" ? (
									<>
										<li>
											<b>HR Process Automation: </b>Automates many manual HR
											processes, such as time off request management,
											performance appraisal, and compensation management.
										</li>
										<li>
											<b>Improve talent management: </b>Help companies identify,
											develop and retain key talent.
										</li>
										<li>
											<b>Performance Data Analytics: </b>Enables companies to
											collect and analyze data on employee performance, enabling
											them to make data-driven decisions about their talent
											management.
										</li>
										<li>
											<b>Global collaboration: </b>It is a cloud solution, which
											allows companies to collaborate more efficiently and
											effectively with their teams distributed around the world.
										</li>
									</>
								) : (
									<>
										<li>
											<b>Automatización de procesos RRHH:</b> Automatiza muchos
											procesos manuales de recursos humanos, como la gestión de
											solicitudes de tiempo libre, la evaluación del desempeño y
											la gestión de compensaciones.
										</li>
										<li>
											<b>Mejora de la gestión de talento:</b> Ayuda a las
											empresas a identificar, desarrollar y retener talentos
											clave.
										</li>
										<li>
											<b>Análisis de datos de desempeño:</b> Permite a las
											empresas recopilar y analizar datos sobre el desempeño de
											los empleados, lo que les permite tomar decisiones basadas
											en datos sobre su gestión del talento.
										</li>
										<li>
											<b>Colaboración global:</b> Es una solución en la nube, lo
											que permite a las empresas colaborar de manera más
											eficiente y efectiva con sus equipos distribuidos en todo
											el mundo.
										</li>
									</>
								)}
							</ul>
							{language !== "es-mx" ? (
								<p>
									Overall, SuccessFactors can help companies improve their human
									resource management, drive employee performance, and
									ultimately drive their success and growth.
								</p>
							) : (
								<p>
									En general, SuccessFactors puede ayudar a las empresas a
									mejorar su gestión de recursos humanos, impulsar el desempeño
									de sus empleados y, en última instancia, impulsar su éxito y
									crecimiento.
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="mb-16">
					<div className="lg:w-[966px] lg:h-[544px] m-auto">
						<iframe
							class="w-full aspect-video"
							src="https://www.youtube.com/embed/ppIuauhCAYA"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
