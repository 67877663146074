import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
	name: "language",
	initialState: {
		// value: localStorage.getItem("language") === null ? "es-mx" : "en-us",
		value: "en-us",
	},
	reducers: {
		changeLanguage: (state, action) => {
			state.value = localStorage.getItem("language");
		},
	},
});

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions;
