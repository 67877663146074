import React from "react";
import Footer from "../../components/global/Footer";
import imgCC from "../../css/assets/cloud.jpg";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const CloudComputing = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "",
			text: "Cloud Computing",
		},
	];
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="container lg:p-8 p-6 flex lg:flex-row flex-col items-center lg:gap-10 mti-container">
				<div className="flex flex-col gap-4">
					{language !== "es-mx" ? (
						<>
							{" "}
							<h1 className="lg:text-4xl text-2xl">
								Cloud Computing: Welcome to the Future
							</h1>
							<em>itUser, Tech & Business, 2023</em>
							<div className="flex flex-col gap-6 max-w-[860px] py-8">
								<p>
									There is no doubt that cloud computing is one of the most
									far-reaching technological innovations of our time. In the
									past, companies managed their hardware and software in a
									physical data center, but the virtual nature of the cloud
									offers a much more efficient alternative.
								</p>
								<p>
									What's more, the cloud creates the ability to perform updates
									in real-time, and the possibility of operating with
									subscription models, such as Apple Music, thanks to all the
									data hosted in the remote network of cloud servers.
								</p>
								<p>
									At SAP there are more than 230 million cloud customers and
									they keep adding. Producing business software since 1972,
									aiming to find the sweet spot where extensive business
									experience can be combined with the most advanced cloud
									technology.
								</p>
							</div>
						</>
					) : (
						<>
							{" "}
							<h1 className="lg:text-4xl text-2xl">
								Cloud Computing: Bienvenidos al futuro
							</h1>
							<em>itUser, Tech & Business, 2023</em>
							<div className="flex flex-col gap-6 max-w-[860px] py-8">
								<p>
									No cabe duda de que cloud computing es una de las innovaciones
									tecnológicas más trascendentales de nuestro tiempo. En el
									pasado, las empresas gestionaban su hardware y software en un
									centro de datos físico, pero la naturaleza virtual de la nube
									ofrece una alternativa mucho más eficiente.
								</p>
								<p>
									Es más, la nube crea la capacidad de realizar actualizaciones
									en tiempo real, y la posibilidad de operar con modelos de
									suscripción, como Apple Music, gracias a todos los datos
									alojados en la red remota de servidores cloud.
								</p>
								<p>
									En SAP existen más de 230 millones de clientes en la nube y se
									siguen sumando. Produciendo software para empresas desde 1972,
									teniendo como objetivo encontrar el punto óptimo en el que se
									pueda combinar una amplia experiencia empresarial con la
									tecnología en la nube más avanzada.
								</p>
							</div>
						</>
					)}
				</div>
				<div>
					<img src={imgCC} width={"700px"} />
				</div>
			</div>
			<Footer />
		</>
	);
};
