import mobileAlimentaria from "../css/assets/industrias/mobile-alimentaria.jpg";
import mobileAutomotriz from "../css/assets/industrias/automotive.jpg";
import mobileEducacion from "../css/assets/industrias/mobile-educacion.jpg";
import mobileHotelera from "../css/assets/industrias/hotelier.jpg";
import mobileManufactura from "../css/assets/industrias/mobile-manufactura.jpg";

export const industriesData = {
	industries: [
		{
			type: "Industria",
			title: "Alimentaria",
			content:
				"Permite a las empresas de la industria de alimentos y bebidas garantizar la seguridad alimentaria, cumplir con estándares industriales y simplificar los procesos.",
			button: {
				href: "/industrias/alimentaria",
				text: "Saber más",
				textEng: "Know more",
				target: "_self",
			},
			src: mobileAlimentaria,
			titleEng: "Food",
			contentEng:
				"It enables food and beverage companies to ensure food safety, meet industry standards, and simplify processes.",
		},
		{
			type: "Industria",
			title: "Automotriz",
			content:
				"Cada vez son más necesarios los automóviles cómodos, rápidos, seguros y rentables, que además utilicen energías sustentables para disminuir los niveles de contaminación.",
			button: {
				href: "/industrias/automotriz",
				text: "Saber más",
				textEng: "Know more",
				target: "_self",
			},
			src: mobileAutomotriz,
			titleEng: "Automotive",
			contentEng:
				"Comfortable, fast, safe, and profitable cars are increasingly needed, which also use sustainable energy to reduce pollution levels.",
		},
		{
			type: "Industria",
			title: "Educación",
			content:
				"SAP Educación proporciona todas las herramientas necesarias para abordar la gestión de los alumnos, del personal de administración y servicios.",
			button: {
				href: "/industrias/educacion",
				text: "Saber más",
				textEng: "Know more",
				target: "_self",
			},
			src: mobileEducacion,
			titleEng: "Education",
			contentEng:
				"SAP Education provides all the necessary tools to address the management of students, administration staff, and services.",
		},
		{
			type: "Industria",
			title: "Hotelera",
			content:
				"SAP S/4 Hana no se trata de una herramienta común, sino de una plataforma que puede llevar a un negocio a un nivel muy superior a lo planeado.",
			button: {
				href: "/industrias/hotelera",
				text: "Saber más",
				textEng: "Know more",
				target: "_self",
			},
			src: mobileHotelera,
			titleEng: "Hotelier",
			contentEng:
				"SAP S/4 Hana is not a common tool but a platform that can take a business to a higher level than planned.",
		},
		{
			type: "Industria",
			title: "Manufactura",
			content:
				"La industria de la manufactura ha evolucionado en la última década, transformándose en una entidad más compleja, digitalizada y en constante cambio.",
			button: {
				href: "/industrias/manufactura",
				text: "Saber más",
				textEng: "Know more",
				target: "_self",
			},
			src: mobileManufactura,
			titleEng: "Manufacture",
			contentEng:
				"The manufacturing industry has evolved in the last decade, becoming a more complex, digitized, and constantly changing entity.",
		},
	],
};
