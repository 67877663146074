import React from "react";
import backgroundImage from "../../css/assets/sap-products-page.jpg";
const HeroSapPage = () => {
	return (
		<section
			className={`${
				backgroundImage ? "min-h-[195px] lg:min-h-[330px]" : ""
			} hero-full relative`}
		>
			<img
				src={backgroundImage}
				className="w-full h-[360px] lg:h-[450px] object-cover object-middle"
			/>
		</section>
	);
};

export default HeroSapPage;
