import imgMision from "../css/assets/icons/mision.png";
import imgVision from "../css/assets/icons/vision.png";
import imgValues from "../css/assets/icons/valores.png";

export const valuesData = {
	values: [
		{
			src: imgMision,
			title: "Misión",

			content:
				"Buscamos proporcionar soluciones innovadoras y eficaces a nuestros clientes en el uso de los sistemas SAP. Nos esforzamos por comprender y satisfacer las necesidades únicas de cada cliente, brindando un soporte excepcional y una implementación eficiente.",
			size: "w-[265px]",
			titleEng: "Mission",
			contentEng:
				"We seek to provide innovative and effective solutions to our clients in the use of SAP systems. We strive to understand and meet each client’s unique needs, providing exceptional support and efficient implementation.",
		},
		{
			src: imgVision,
			title: "Visión",
			content:
				"Ser líderes en la industria de la consultoría de SAP, brindando soluciones innovadoras y eficientes a nuestros clientes y transformando sus negocios para alcanzar su máximo potencial.",
			size: "w-[350px]",
			titleEng: "Vision",
			contentEng:
				"To be leaders in the SAP consulting industry, providing innovative and efficient solutions to our clients and transforming their businesses to reach their full potential.",
		},
		{
			src: imgValues,
			title: "Valores",
			content:
				"Nos enfocamos en brindar soluciones innovadoras y personalizadas a nuestros clientes para ayudarlos a alcanzar sus objetivos de negocio.",
			size: "lg:px-28",
			titleEng: "Values",
			contentEng:
				"We focus on providing innovative and customized solutions to our clients to help them achieve their business objectives.",
		},
	],
};
