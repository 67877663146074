import React from "react";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Footer from "../../components/global/Footer";
import automotriz from "../../css/assets/industrias/automotive.jpg";
import Header from "../Header";
import { useSelector } from "react-redux";

export const IndustriaAutomotriz = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "/",
			text: "Industrias",
		},
		{
			href: "",
			text: "Automoriz",
		},
	];
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-center font-body mti-container">
				<img className="max-h-[800px]" src={automotriz} alt="Your Company" />
				<div className="absolute flex items-start flex-col lg:mt-[400px] md:mt-[280px] mt-[120px] sm:px-8 px-4">
					<div className="text-[#F7F7F7] tracking-[1px] uppercase">
						{language !== "es-mx" ? "industries" : "industrias"}
					</div>
					<div className="text-[#F7F7F7] md:text-4xl text-2xl flex items-end flex-col tracking-[2.5px]">
						<span></span>
						{language !== "es-mx" ? "Automotive" : "Automotriz"}
					</div>
				</div>
			</div>
			<div className="font-body lg:mt-16 mt-8 container p-4">
				{language !== "es-mx" ? (
					<>
						<div className="font-bold text-lg mt-8 mb-20 space-y-2">
							<p>
								The dynamics and limitations of highly regulated industries
								create the need for a technological platform that allows
								integrated information management.
							</p>
							<p>
								MTi Consulting for the Automotive Industry incorporates industry
								best practices and facilitates regulatory compliance and
								delivery models for organizations that produce and supply auto
								parts.
							</p>
						</div>
						<div className="text-xl tracking-[1px]">Benefits</div>
						<div>
							<ul className="font-bold text-lg mt-8 mb-20 space-y-6">
								<li>
									Fast and secure implementation, easily customizable for each
									client's unique needs
								</li>
								<li>Full range of critical industrial processes</li>
								<li>Commercial</li>
								<li>Operations and capacity planningd</li>
								<li>MRP</li>
								<li>Engineering</li>
								<li>Standard cost</li>
								<li>Quality</li>
								<li>
									Reduced the number of line stoppages in customer manufacturing
									plants and increased productivity
								</li>
								<li>
									Mirror model (Plant-SAP) for material flow and operations.
								</li>
							</ul>
						</div>
						<div className="text-xl tracking-[1px]">Why MTi Consulting?</div>
						<div className="font-bold text-lg mt-8 mb-20">
							Extensive experience in the delivery of projects within the
							automotive sector, which allows us to optimize and guarantee
							quality in the delivery of solutions.
						</div>
					</>
				) : (
					<>
						<div className="font-bold text-lg mt-8 mb-20 space-y-2">
							<p>
								La dinámica y las limitaciones de las industrias que están
								altamente reguladas crean la necesidad de una plataforma
								tecnológica que permita la gestión integrada de la información.
							</p>
							<p>
								MTi Consulting para la industria automotriz incorporan las
								mejores prácticas de la industria y facilitan el cumplimiento
								normativo y los modelos de entrega para las organizaciones que
								producen y suministran autopartes.
							</p>
						</div>
						<div className="text-xl tracking-[1px]">Beneficios</div>
						<div>
							<ul className="font-bold text-lg mt-8 mb-20 space-y-6">
								<li>
									Implementación rápida y segura, fácilmente personalizable para
									las necesidades únicas de cada cliente.
								</li>
								<li>Gama completa de procesos industriales críticos</li>
								<li>Comercial</li>
								<li>Operaciones y planificación de capacidad</li>
								<li>MRP</li>
								<li>Ingeniería</li>
								<li>Costo estándar</li>
								<li>Calidad</li>
								<li>
									Número reducido de paros de línea en plantas de fabricación de
									clientes y aumento de la productividad.
								</li>
								<li>
									Modelo espejo (Plant-SAP) para flujo de materiales y
									operaciones.
								</li>
							</ul>
						</div>
						<div className="text-xl tracking-[1px]">
							¿Por qué MTi Consulting?
						</div>
						<div className="font-bold text-lg mt-8 mb-20">
							Amplia experiencia en la entrega de proyectos dentro del sector
							automotriz, lo que nos permite optimizar y garantizar la calidad
							en la entrega de soluciones.
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};
