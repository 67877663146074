import React from "react";
import Footer from "../../components/global/Footer";
import HeroSapPage from "../../components/global/HeroSapPage";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const SapR3 = () => {
	const language = useSelector((state) => state.language.value);

	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
			textEng: "Home",
		},
		{
			href: "/productos-sap",
			text: "Productos SAP",
			textEng: "SAP Products",
		},
		{
			href: "",
			text: "SAP R3",
			textEng: "SAP R3",
		},
	];
	return (
		<div>
			<Header />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<HeroSapPage />
			<div className="container mti-container">
				<div className="font-body px-6">
					<div className="text-4xl lg:text-6xl tracking-[1px] text-[#3991d2] py-6 lg:py-10">
						{/* {title} */}
						SAP R3
					</div>
					<div
						className={
							"flex flex-col text-base lg:text-lg tracking-[1px] mb-16"
						}
					>
						<div className="w-auto space-y-8 lg:space-y-10">
							{language !== "es-mx" ? (
								<>
									<p>
										SAP R/3 it is one of the most widely used ERP systems in the
										world. world. It was launched in 1992 and has since evolved
										until it becomes a comprehensive business solution that
										encompasses a wide variety of business processes, including
										finance, logistics, supply chain management, sales and
										distribution, human resources, and more.
									</p>
									<p>
										<b>What is SAP R3?</b>
									</p>
									<p>
										An ERP is a tool for the integral management of the company
										that provides tools for the planning, analysis, and control
										of the company's resources.
									</p>
									<p>
										The support for the operation and development of
										functionalities and customized reports are within the focus
										of MTi. SAP system implementations are suggested on SAP S/4
										HANA versions or higher.
									</p>
								</>
							) : (
								<>
									<p>
										SAP R/3 es uno de los sistemas ERP más utilizados en todo el
										mundo. Fue lanzado en 1992 y desde entonces ha evolucionado
										hasta convertirse en una solución empresarial integral que
										abarca una amplia variedad de procesos empresariales,
										incluyendo finanzas, logística, gestión de la cadena de
										suministro, ventas y distribución, recursos humanos, y más.
									</p>
									<p>
										<b>¿Qué es SAP R3?</b>
									</p>
									<p>
										Un ERP es una herramienta para la gestión integral de la
										empresa que proporciona herramientas para la planificación,
										análisis y control de los recursos de la empresa.
									</p>
									<p>
										El soporte a la operación y desarrollos de funcionalidades y
										reportes customizados, están dentro del enfoque de MTi. Las
										implementaciones del sistema SAP se sugieren en versiones
										SAP S/4 HANA o superiores.
									</p>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="mb-16">
					<div className="lg:w-[966px] lg:h-[544px] m-auto">
						<iframe
							class="w-full aspect-video"
							src="https://www.youtube.com/embed/k_WjzLqI0ho"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
