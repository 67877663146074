import React, { useEffect, useRef, useState } from "react";

import telefono from "../css/assets/icons/contacto.png";
import reloj from "../css/assets/icons/reloj.png";
import Footer from "../components/global/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import "../css/pages/Contact.css";
import pdf from "../docs/Aviso-de-privacidad.pdf";
import swal from "sweetalert";
import emailjs from "@emailjs/browser";
import Layout from "./Header";
import Header from "./Header";
import { useSelector } from "react-redux";

const Contacto = () => {
	const captcha = useRef(null);
	const [inputs, setInputs] = useState(null);
	const [txtArea, setTxtArea] = useState(null);
	const [form, setForm] = useState(null);

	const formtoSend = useRef();

	const language = useSelector((state) => state.language.value);

	const expresiones = {
		user_name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
		user_email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
		phone: /^\d{7,14}$/, // 7 a 14 numeros.
	};

	const campos = {
		user_name: false,
		user_lastname: false,
		user_email: false,
		user_phone: false,
		company: false,
		message: false,
	};

	useEffect(() => {
		setForm(document.getElementById("formularioMTI"));
		setInputs(document.querySelectorAll("#formularioMTI input"));
		setTxtArea(document.querySelectorAll("#formularioMTI textarea"));
	}, [document]);

	const validarFormulario = (e) => {
		switch (e.target.name) {
			case "user_name":
				validarCampo(expresiones.user_name, e.target, "user_name");
				break;
			case "user_lastname":
				validarCampo(expresiones.user_name, e.target, "user_lastname");
				break;
			case "company":
				validarCampo(expresiones.user_name, e.target, "company");
				break;
			case "user_email":
				validarCampo(expresiones.user_email, e.target, "user_email");
				break;
			case "user_phone":
				validarCampo(expresiones.phone, e.target, "user_phone");
				break;
			case "message":
				validarCampo(expresiones.user_name, e.target, "message");
				break;
		}
	};

	const validarCampo = (expresion, input, campo) => {
		if (expresion.test(input.value)) {
			// document.getElementById(`grupo__${campo}`).classList.remove('formulario__grupo-incorrecto');
			// document.getElementById(`grupo__${campo}`).classList.add('formulario__grupo-correcto');
			// document.querySelector(`#grupo__${campo} i`).classList.add('fa-check-circle');
			// document.querySelector(`#grupo__${campo} i`).classList.remove('fa-times-circle');
			document
				.querySelector(`#grupo__${campo} .formulario__input-error`)
				.classList.remove("formulario__input-error-activo");
			campos[campo] = true;
		} else {
			// document.getElementById(`grupo__${campo}`).classList.add('formulario__grupo-incorrecto');
			// document.getElementById(`grupo__${campo}`).classList.remove('formulario__grupo-correcto');
			// document.querySelector(`#grupo__${campo} i`).classList.add('fa-times-circle');
			// document.querySelector(`#grupo__${campo} i`).classList.remove('fa-check-circle');
			document
				.querySelector(`#grupo__${campo} .formulario__input-error`)
				.classList.add("formulario__input-error-activo");
			campos[campo] = false;
		}
	};

	inputs &&
		inputs.forEach((input) => {
			input.addEventListener("keyup", validarFormulario);
			input.addEventListener("blur", validarFormulario);
		});

	txtArea &&
		txtArea.forEach((input) => {
			input.addEventListener("keyup", validarFormulario);
			input.addEventListener("blur", validarFormulario);
		});

	const sendEmail = () => {
		emailjs
			.sendForm(
				"service_p0xiwil",
				"template_mckvqky",
				formtoSend.current,
				"1l1g0GdZf-Jvchb56"
			)
			.then(
				(result) => {
					swal({
						text:
							language !== "es-mx"
								? "Your request has been sent correctly."
								: "Su solicitud a sido enviada correctamente.",
						icon: "success",
						button: language !== "es-mx" ? "Close" : "Cerrar",
					});
					form.reset();
				},
				(error) => {
					swal({
						text:
							language !== "es-mx"
								? "There was an error submitting your request."
								: "Hubo un error al enviar su solicitud.",
						icon: "error",
						button: language !== "es-mx" ? "Close" : "Cerrar",
					});
				}
			);
	};

	const submit = (e) => {
		e.preventDefault();
		if (
			campos.user_name &&
			campos.message &&
			campos.user_email &&
			campos.user_phone &&
			campos.user_lastname &&
			campos.company
		) {
			if (captcha.current.getValue()) {
				sendEmail();
			} else {
				swal({
					text:
						language !== "es-mx"
							? "Please complete the requested information (recaptcha)"
							: "Favor de completar la informacion solicitada (recaptcha)",
					icon: "warning",
					button: language !== "es-mx" ? "Close" : "Cerrar",
				});
			}
		} else {
			swal({
				text:
					language !== "es-mx"
						? "Please complete the requested information (*)"
						: "Favor de completar la informacion solicitada (*)",
				icon: "warning",
				button: language !== "es-mx" ? "Close" : "Cerrar",
			});
		}
	};

	return (
		<>
			<Header />
			<div className="container py-12 mti-container">
				<div className="flex flex-col gap-6">
					<h1 className="text-4xl">
						{language !== "es-mx" ? "Contact us" : "Contáctenos"}
					</h1>
					<p className="text-xl mb-8">
						{language !== "es-mx"
							? "Get more information, and one of our representatives will answer the request."
							: "Obten más información, uno de nuestros representantes atenderá la solicitud."}
					</p>
				</div>

				<form onSubmit={submit} id="formularioMTI" ref={formtoSend}>
					<div className="grid lg:grid-cols-2 gap-4">
						<input
							className="h-[52px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
							name="from_name"
							value={"mticonsulting.com"}
							hidden
						/>
						<div id="grupo__user_name" className="max-w-[677px]">
							<label for="user_name" class="formulario__label">
								{language !== "es-mx" ? "Name" : "Nombre"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<input
								placeholder={language !== "es-mx" ? "Name" : "Nombre"}
								className="h-[52px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="user_name"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
						<div id="grupo__user_lastname" className="max-w-[677px]">
							<label for="user_lastname" class="formulario__label">
								{language !== "es-mx" ? "Lastname" : "Apellido"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<input
								placeholder={language !== "es-mx" ? "Lastname" : "Apellido"}
								className="h-[52px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="user_lastname"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
						<div id="grupo__user_email" className="max-w-[677px]">
							<label for="user_email" class="formulario__label">
								{language !== "es-mx" ? "Email" : "Correo Electrónico"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<input
								placeholder={
									language !== "es-mx" ? "mail@mail.com" : "correo@correo.com"
								}
								className="h-[52px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="user_email"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
						<div id="grupo__user_phone" className="max-w-[677px]">
							<label for="user_phone" class="formulario__label">
								{language !== "es-mx" ? "Phone" : "Telefono"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<input
								placeholder="123456789"
								className="h-[52px]  bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="user_phone"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
						<div id="grupo__company" className="max-w-[677px]">
							<label for="company" class="formulario__label">
								{language !== "es-mx" ? "Company" : "Compañia"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<input
								placeholder={
									language !== "es-mx"
										? "MTi Consulting i.e"
										: "MTi Consulting (ejemplo)"
								}
								className="h-[52px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="company"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
						<div id="grupo__message" className="max-w-[677px]">
							<label for="message" class="formulario__label">
								{language !== "es-mx" ? "Message" : "Mensaje"}
								<span style={{ color: "red" }}> *</span>:
							</label>
							<textarea
								placeholder={language !== "es-mx" ? "Message" : "Mensaje"}
								className="h-[150px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px] text-[#222222] w-full"
								name="message"
								autoComplete="off"
							/>
							<p class="formulario__input-error">
								{language !== "es-mx"
									? "This field is required."
									: "Favor de llenar este campo."}
							</p>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row justify-between lg:pt-20 pt-10 gap-8">
						{language !== "es-mx" ? (
							<div className="max-w-[300px]">
								By completing this form, I understand that my information will
								be processed by MTi Consulting as mentioned in its
								<a href={pdf} rel="noopener noreferrer" target="_blank">
									<b> Privacy Notice.</b>
								</a>
							</div>
						) : (
							<div className="max-w-[300px]">
								Al completar este formulario, comprendo que mi infomración será
								procesada por MTi Consulting como se menciona en su{" "}
								<a href={pdf} rel="noopener noreferrer" target="_blank">
									<b>Aviso de Privacidad.</b>
								</a>
							</div>
						)}

						<div>
							<ReCAPTCHA
								ref={captcha}
								sitekey="6LeUJyomAAAAAD2zzfQcR9qM45FoQtKkDarc9wrn"
							/>
						</div>
						<div>
							<button className="text-[#F7F7F7] bg-[#3991d2] py-3 px-10 mb-12 uppercase text-lg rounded-[6px] hover:bg-[#4597D3] tracking-[1px] hover:underline">
								{language !== "es-mx" ? "Submit" : "Enviar"}
							</button>
						</div>
					</div>
				</form>
				{/* <div className="relative flex items-start flex-col justify-center font-body min-h-screen w-auto">
					<div className="absolute bg-[#000] min-w-[992px] min-h-[750px] text-white ml-[200px]">
						<div className="ml-[325px] mt-[100px]">
							<div className="text-[#3391d2] text-4xl mb-4">
								Obten más información
							</div>
							<div className="text-lg mb-8 w-[350px] uppercase">
								uno de nuestros representantes atenderá la solicitud.
							</div>
							<div className="flex flex-col w-[200px]">
								<form>
									<input
										placeholder="Nombre"
										className="h-[52px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>
									<input
										placeholder="Apellido"
										className="h-[52px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>
									<input
										placeholder="Email"
										className="h-[52px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>{" "}
									<input
										placeholder="Telefono"
										className="h-[52px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>
									<input
										placeholder="Compañia"
										className="h-[52px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>
									<textarea
										placeholder="Mensaje"
										className="h-[150px] w-[350px] bg-[#ececed] rounded-[4px] p-[10px] tracking-[1px]"
									/>
								</form>
								<div>
									<button
										className="text-[#F7F7F7] bg-[#3991d2] py-3 px-10 mb-12 uppercase text-lg rounded-[6px] hover:bg-[#4597D3] tracking-[1px] hover:underline"
										onClick={() => {
											console.log("entro");
										}}
									>
										Enviar
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="absolute bg-[#3391d2] min-w-[450px] min-h-[600px] text-white">
						<div className="ml-[75px] mt-[100px] w-[300px]">
							<div className="text-white text-4xl mb-16">Contacto</div>
							<div className="text-white flex ">
								<div>
									<img
										src={telefono}
										alt=""
										className="h-[25px] mt-[10px] mr-[50px]"
									/>
								</div>
								<div className="flex flex-col mb-12">
									<div className="">
										<a
											href="tel:+5218113015642"
											className="text-white no-underline hover:underline"
										>
											+52 1 (811) 301-5642
										</a>
									</div>
									<div>
										<a
											href="tel:+5218110763218"
											className="text-white no-underline hover:underline"
										>
											+52 1 (811) 076-3218
										</a>
									</div>
								</div>
							</div>
							<div className="text-white flex">
								<div>
									<img
										src={reloj}
										alt=""
										className="h-[25px] mt-[10px] mr-[50px]"
									/>
								</div>
								<div>
									<div>Lunes a Viernes </div>
									<div>9:00 am a 6:00 pm </div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>

			<Footer />
		</>
	);
};

export default Contacto;
