import React, { useState, useEffect, useRef } from "react";
import { industriesData } from "../data/industries";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Industries = () => {
	const [nextButtonDisabled, disableNextButton] = useState(false);

	const breakPoints = {
		320: {
			slidesPerView: 1,
			spaceBetween: 18,
		},
		375: {
			slidesPerView: 1,
			spaceBetween: 18,
		},
		425: {
			slidesPerView: 1,
			spaceBetween: 18,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 24,
		},
		992: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	};

	const pagination = {
		clickable: true,
	};

	const language = useSelector((state) => state.language.value);

	return (
		<section className="overflow-hidden mb-8 p-6 font-body">
			<h2 className="lg:text-5xl text-3xl tracking-[1px] text-left mt-6 mb-12  font-bold">
				{language !== "es-mx" ? "Industries" : "Industrias"}
			</h2>
			<div className="">
				<Swiper
					navigation={true}
					pagination={pagination}
					modules={[Navigation, Pagination]}
					breakpoints={breakPoints}
					loop={false}
					spaceBetween={18}
					slidesPerView={3}
					onSlideChange={(swiper) => disableNextButton(swiper.isEnd)}
				>
					{industriesData &&
						industriesData.industries.map((industry, index) => {
							return (
								<SwiperSlide>
									<div
										className="border border-solid border-gray-30 bg-gray-10 shadow-[2px_2px_6px_rgba(0,0,0,0.1)] flex flex-col flex-grow"
										key={index}
									>
										<div className="industria-img">
											<figure className="w-full">
												<img
													src={industry.src}
													className="block w-full xl:h-auto md:h-[240px] xs:h-auto"
												/>
											</figure>
										</div>
										<div className="px-10 mt-6 mb-8 lg:mt-6 lg:mb-9 space-y-4 ">
											<h3 className="text-xl font-bold">
												{language !== "es-mx"
													? industry.titleEng
													: industry.title}
											</h3>
											<p className="text-base lg:text-md lg:min-h-[240px] xl:min-h-[150px] md:min-h-[200px] min-h-[280px]">
												{language !== "es-mx"
													? industry.contentEng
													: industry.content}
											</p>
											<Link
												className="text-[#F7F7F7] bg-[#3991d2] py-3 md:px-6 px-3 uppercase text-lg rounded-[6px] hover:bg-[#4597D3] tracking-[1px] hover:underline hover:text-[#F7F7F7] w-fit"
												to={industry.button.href}
											>
												{language !== "es-mx"
													? industry.button.textEng
													: industry.button.text}
											</Link>
										</div>
									</div>
								</SwiperSlide>
							);
						})}
				</Swiper>
			</div>
		</section>
	);
};

export default Industries;
