import React from "react";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Footer from "../../components/global/Footer";
import hotelera from "../../css/assets/industrias/hotelier.jpg";
import Header from "../Header";
import { useSelector } from "react-redux";

export const Hotelera = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "/",
			text: "Industrias",
		},
		{
			href: "",
			text: "Hotelera",
		},
	];
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-center font-body mti-container">
				<img className="max-h-[800px]" src={hotelera} alt="Your Company" />
				<div className="absolute flex items-start flex-col lg:mt-[400px] md:mt-[280px] mt-[120px] sm:px-8 px-4">
					<div className="text-[#F7F7F7] tracking-[1px] uppercase">
						{language !== "es-mx" ? "industries" : "industrias"}
					</div>
					<div className="text-[#F7F7F7] md:text-4xl text-2xl flex items-end flex-col tracking-[2.5px]">
						<span>{language !== "es-mx" ? "Hotelier" : "Hotelera"}</span>
					</div>
				</div>
			</div>
			<div className="font-body lg:mt-16 mt-8 container p-4">
				{language !== "es-mx" ? (
					<>
						<div className="font-bold text-lg mt-8 mb-12">
							Retaining and retaining customers has become the most important
							objective in hotel chains and for this, it is necessary to have
							the most efficient tools that optimize the different processes
							such as supplier and employee management, and thus ensure that
							customers experience the best experience.
						</div>
						<div className="font-bold text-lg mt-8 mb-12">
							These are some advantages of using SAP S/4 Hana in the hotel
							industry:
						</div>
						<div className="space-y-6 lg:space-y-8 mb-16">
							<p>
								<b>1. Efficient management and control</b>
								<br /> The SAP S/4 Hana tool allows hotels to have better
								control of their different activities. That is, centralize or
								integrate real-time laundry, kitchen, parking, or parking
								services, among others. It has a high management capacity.
							</p>
							<p>
								<b>2. Automatic personalization</b>
								<br /> System customization used to take hours because the
								process was done manually. With this new technology, the
								customer only needs to answer a few questions for the tool to
								automatically configure itself. Each area is customizable.
							</p>
							<p>
								<b>3. Scalability</b>
								<br /> The system can adapt to each stage of a business. That
								is, if the hotel acquired it because it was in its infancy, the
								SAP infrastructure can be coupled with the growth of the
								organization. In this way, there is no need to renew anything.
							</p>
							<p>
								<b>4. Optimal interaction with hotel guests</b>
								<br /> The SAP S/4 Hana system allows hotel users to receive
								information or carry out activities through digital media. We
								refer to promotions, reservations, and payments, among other
								options without barriers of time and space.
							</p>
							<p>
								<b>5. Better control over human capital</b>
								<br /> The module that the SAP S/4 Hana tool brings with it
								allows hiring and evaluating the work of personnel quickly and
								easily. Likewise, manage compensation, among others. Let's
								remember that dozens of employees can work inside a hotel
								according to its infrastructure, branches, services, or seasons.
							</p>
							<p>
								<b>6. SAP Finance module</b>
								<br /> It provides the option of shared services to companies,
								making it more efficient for a hotel concerning its financial
								operations.
							</p>
						</div>
					</>
				) : (
					<>
						<div className="font-bold text-lg mt-8 mb-12">
							Conservar y fidelizar a los clientes se ha vuelto el objetivo más
							importante en las cadenas hoteleras y para esto, es necesario
							contar con las herramientas más eficientes que optimicen los
							diferentes procesos como la gestión de proveedores y empleados y
							así lograr que los clientes vivan la mejor experiencia.
						</div>
						<div className="font-bold text-lg mt-8 mb-12">
							Estas son algunas ventajas de usar SAP S/4 Hana en el giro
							hotelero:
						</div>
						<div className="space-y-6 lg:space-y-8 mb-16">
							<p>
								<b>1. Eficiente gestión y control</b>
								<br /> La herramienta SAP S/4 Hana permite a los hoteles llevar
								un mejor control de sus distintas actividades. Es decir,
								centralizar o integrar en tiempo real servicios de lavandería,
								cocina, parqueo o parking, entre otros. Presenta una alta
								capacidad de gestión.
							</p>
							<p>
								<b>2. Personalización automática</b>
								<br /> La personalización del sistema solía costar horas debido
								a que el proceso se realizaba de manera manual. Con esta nueva
								tecnología, el cliente solo necesita contestar algunas preguntas
								para que la herramienta se configure automáticamente. Cada área
								es personalizable.
							</p>
							<p>
								<b>3. Escalabilidad</b>
								<br /> El sistema tiene la capacidad de adaptarse a cada etapa
								de un negocio. Es decir, si el hotel lo adquirió de forma básica
								porque estaba en sus inicios, la infraestructura de SAP puede
								acoplarse al crecimiento de la organización. De esta manera, no
								hay la necesidad de renovar nada.
							</p>
							<p>
								<b>4. Óptima interacción con los clientes del hotel</b>
								<br /> El sistema SAP S/4 Hana permite a los usuarios del hotel
								recibir información o realizar actividades a través de medios
								digitales. Nos referimos a promociones, reservas, pagos, entre
								otras opciones sin barreras de tiempo y espacio.
							</p>
							<p>
								<b>5. Mejor control sobre el capital humano</b>
								<br /> El módulo que trae consigo la herramienta “sap hana s4”
								permite contratar y evaluar el trabajo del personal de forma
								rápida y sencilla. Así mismo, gestionar compensaciones, entre
								otros. Recordemos que dentro de un hotel pueden trabajar decenas
								de empleados de acuerdo a su infraestructura, sucursales,
								servicios o temporadas.
							</p>
							<p>
								<b>6. Módulo SAP de finanzas</b>
								<br /> Brinda la opción de servicios compartidos a las empresas
								por lo que a un hotel le resulta más eficiente respecto a sus
								operaciones financieras.
							</p>
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};
