import React from "react";
import Fade from "react-reveal/Fade";
import heroBannerImg from "../css/assets/heroImage.jpg";
import { servicesData } from "../data/services";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// import "../css/components/HeroImage.css";

const HeroImage = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<div className="relative flex items-end flex-col justify-center font-body">
				<img className="w-full" src={heroBannerImg} alt="Your Company" />
				<Fade right>
					<div className="absolute px-8 flex items-end flex-col xl:mt-[360px] lg:mt-[160px] md:mt-[200px]">
						<div className="text-[#F7F7F7] lg:text-2xl text-xs tracking-[1px]">
							{language !== "es-mx" ? "SAP Consulting" : "CONSULTORíA SAP"}
						</div>
						<div className="text-[#F7F7F7] lg:text-6xl md:text-2xl text-lg flex items-end flex-col">
							<span> {language !== "es-mx" ? "We seek" : "Buscamos ser"}</span>
							<span>
								{language !== "es-mx"
									? "to be your digital partners"
									: "tus socios digitales"}
							</span>
							<span>
								{language !== "es-mx"
									? "and fully exploit "
									: "y explotar al máximo"}
							</span>
							<span>
								{language !== "es-mx" ? "your potential" : "tu potencial"}
							</span>
						</div>
					</div>
				</Fade>
			</div>
			<div className="relative bg-[#000000] h-[380px] w-auto flex items-center flex-col justify-center font-body p-6">
				<Fade bottom>
					<div className="absolute lg:h-[90px] h-[45px] w-auto bg-[#222222] top-0 right-0 lg:mt-[-50px] mt-[-20px] flex items-end px-20">
						{servicesData &&
							servicesData.services?.map((item, index) => (
								<div className="hidden lg:block " key={index}>
									<div className="flex space-x-6 h-[90px] items-center">
										<Link
											to={item.href}
											className="text-white px-3 py-3 text-md font-medium no-underline font-body hover:underline uppercase tracking-[1px]"
										>
											{item.title}
										</Link>
									</div>
								</div>
							))}
						<div className="block lg:hidden">
							<div className="flex space-x-2 h-[45px] items-center">
								<Link
									to={"/productos-sap"}
									className="text-white px-3 py-3 text-md font-medium no-underline font-body hover:underline uppercase tracking-[1px]"
								>
									{language !== "es-mx" ? "Products" : "Productos"}
								</Link>
							</div>
						</div>
					</div>
				</Fade>
				<div>
					<Fade bottom>
						<div className="text-[#F7F7F7] lg:text-4xl md:text-2xl text-lg tracking-wide text-center">
							{language !== "es-mx" ? (
								"Your goals we make ours!"
							) : (
								<>
									<sub>¡</sub>Tus metas las hacemos nuestras!
								</>
							)}
						</div>
						<div className="font-bold text-[#F7F7F7] lg:text-xl text-base w-auto flex items-center flex-col mt-6 sm:text-center">
							<span className="tracking-[1.5px]">
								{language !== "es-mx"
									? "Since 2015 we have built a list"
									: "Desde el año 2015 hemos constituido una lista"}
							</span>
							<span className="tracking-[1.5px]">
								{language !== "es-mx"
									? "satisfied clients in SAP consulting."
									: "de clientes satisfechas en consultoría SAP."}
							</span>
							<span className="tracking-[1.5px]">
								{language !== "es-mx"
									? "Our commitment and delivery of each service"
									: "Nuestro compromiso y entrega en cada servicio"}
							</span>
							<span className="tracking-[1.5px]">
								{language !== "es-mx"
									? "are a constant in solving your problems."
									: "es una constante en la solución de tus problemas."}
							</span>
						</div>
					</Fade>
				</div>
			</div>
		</>
	);
};

export default HeroImage;
