import React from "react";
import Footer from "../../components/global/Footer";
import imgAgave from "../../css/assets/agave.jpg";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const ProductoresDeAgave = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "",
			text: "Productores de Agave",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="container lg:p-8 p-6 flex lg:flex-row flex-col items-center gap-10 mti-container">
				<div className="flex flex-col gap-4">
					{language !== "es-mx" ? (
						<>
							<h1 className="lg:text-4xl text-2xl">
								Agave producers rely on SAP technologies
							</h1>
							<em>Reseller Redactores, 2023</em>
							<div className="flex flex-col gap-6 max-w-[860px] py-8">
								<p>
									Companies such as Inulina y Miel Agave (IMAG) and Mieles
									Campos Azules positioned themselves in the agave processing
									industry after implementing the SAP Business One solution.
								</p>
								<p>
									According to Deloitte research,
									<b>
										 80 percent of small businesses are not taking advantage of
										digital tools
									</b>
									, missing out on important growth opportunities.
								</p>
								<p>
									However, in the remaining group, various Mexican companies
									have benefited from SAP technology to achieve their objectives
									and consolidate in their different market niches. Companies
									such as
									<b> Inulina y Miel Agave IMAG y Mieles Campos Azules </b>
									positioned themselves in the agave processing industry after
									implementing the SAP Business One solution.
								</p>
								<p>
									The Jalisco company
									<b>
										 IMAG, which has more than 50 years of experience in the
										cultivation of agave, has streamlined production with SAP, 
									</b>
									obtaining as a result a more sophisticated supply chain,
									consolidation of all its internal processes, and a global and
									interactive business for decision-making, which has helped all
									employees to do their job better, going from being data
									capturers to information analyzers.
								</p>
								<p>
									Another company from Jalisco and agave producer,
									<b>
										 Mieles Campos Azules, belonging to Grupo Solave, has
										optimized production with SAP. 
									</b>
									With all production facilities now operating from a single
									cloud-based platform and automation of all companies,
									management can get accurate monitoring at the click of a
									button, improving decision-making.
								</p>
							</div>
						</>
					) : (
						<>
							<h1 className="lg:text-4xl text-2xl">
								Productores de agave se apoyan de tecnologías de SAP
							</h1>
							<em>Reseller Redactores, 2023</em>
							<div className="flex flex-col gap-6 max-w-[860px] py-8">
								<p>
									Compañías como Inulina y Miel Agave (IMAG) y Mieles Campos
									Azules se posicionaron en la industria de procesamiento de
									agave tras implementar la solución SAP.
								</p>
								<p>
									De acuerdo con una investigación de Deloitte, el 
									<b>
										80 por ciento de los pequeños negocios no están sacando
										provecho de las herramientas digitales
									</b>
									, lo que les hace perder importantes oportunidades de
									crecimiento.
								</p>
								<p>
									Sin embargo, en el grupo restante se encuentran diversas
									empresas mexicanas que se han beneficiado de la tecnología de
									SAP para lograr sus objetivos y consolidarse en sus distintos
									nichos de mercado. Compañías como
									<b> Inulina y Miel Agave IMAG y Mieles Campos Azules</b> se
									posicionaron en la industria de procesamiento de agave tras
									implementar la solución SAP.
								</p>
								<p>
									La empresa jalisciense
									<b>
										 IMAG, que cuenta con más de 50 años de experiencia en el
										cultivo de agave, ha agilizado la producción con SAP 
									</b>
									obteniendo como resultado una cadena de suministro más
									sofisticada, una consolidación de todos sus procesos internos
									y una visión global e interactiva del negocio para la toma de
									decisiones, lo que ha ayudado a todos los empleados a hacer
									mejor su trabajo, pasando de ser capturistas a analizadores de
									la información.
								</p>
								<p>
									Otra empresa originaria de Jalisco y productora de agave,
									<b>
										 Mieles Campos Azules, perteneciente al Grupo Solave, ha
										optimizado la producción con SAP. 
									</b>
									Con todas las instalaciones de producción ahora operando desde
									una única plataforma basada en la nube y la automatización de
									toda la empresa, la administración puede obtener una
									supervisión precisa con solo hacer clic en un botón, mejorando
									la toma de decisiones.
								</p>
							</div>
						</>
					)}
				</div>
				<div>
					<img src={imgAgave} width={"700px"} />
				</div>
			</div>
			<Footer />
		</>
	);
};
