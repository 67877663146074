export const productsSapData = {
	products: [
		{
			title: "SAP S/4 HANA",
			subtitle: "el erp más poderoso del mercado.",
			subtitleSize: "",
			content:
				"Procesa enormes cantidades de datos a una gran velocidad, permitiendo que tomes decisiones en tiempo real sin importar la complejidad de procedimientos o el tamaño de tu empresa.",
			href: "/productos-sap/sap-s4-hana",
			subtitleEng: "THE MOST POWERFUL ERP ON THE MARKET",
			contentEng:
				"It processes huge amounts of data at high speed, allowing you to make decisions in real-time regardless of the complexity of procedures or the size of your company.",
		},
		{
			title: "SAP R3",
			subtitle:
				"enterprise resource planning - planificación de recursos empresariales.",
			subtitleSize: "w-[530px]",
			content:
				"Un ERP es una herramienta para la gestión integral de la empresa que proporciona herramientas para la planificación, análisis y control de recursos de la empresa.",
			href: "/productos-sap/sap-r3",
			subtitleEng: "ENTERPRISE RESOURCE PLANNING – BUSINESS RESOURCE PLANNING.",
			contentEng:
				"An ERP is a tool for the integral management of the company that provides tools for the planning, analysis, and control of company resources.",
		},
		{
			title: "SAP Business One",
			subtitle: "el mejor erp para pequeñas y medianas empresas.",
			href: "/productos-sap/sap-business-one",
			subtitleSize: "",
			content:
				"SAP Business One es un sistema de gestión empresarial diseñado para PyMEs adaptable on-premise, en la nube y de manera móvil, el cual cuenta con un conjunto de módulos para operar e integrar las diferentes áreas de tu empresa.",
			subtitleEng: "THE BEST ERP FOR SMALL AND MEDIUM BUSINESSES.",
			contentEng:
				"SAP Business One is a business management system designed for SMEs, adaptable on-premise, in the cloud, and mobile, which has a set of modules to operate and integrate the different areas of your company.",
		},
		{
			title: "Success Factors",
			subtitle: "administración del capital humano",
			subtitleSize: "",
			href: "/productos-sap/success-factors",
			content:
				"La solución SAP SuccessFactors Employee Central transforma la gestión de capital humano (SAP HCM) dentro de tu modelo de negocio, combinando la gestión de talento y rendimiento de los empleados con los objetivos de tu negocio.",
			subtitleEng: "HUMAN CAPITAL MANAGEMENT",
			contentEng:
				"The SAP SuccessFactors Employee Central solution transforms human capital management (SAP HCM) within your business model, combining talent management and employee performance with your business objectives.",
		},
		{
			title: "SAP Ariba",
			subtitle: "líder en gestión de gastos",
			href: "/productos-sap/sap-ariba",
			subtitleSize: "",
			content:
				"SAP Ariba brinda soluciones líderes del mercado para digitalizar e integrar todo o parte del proceso del abastecimiento al pago o del pedido al cobre, ofrecidas en la red comercial más grande del mundo, para ayudarlo a modernizar y hacer crecer su negocio.",
			subtitleEng: "LEADER IN EXPENSE MANAGEMENT",
			contentEng:
				"SAP Ariba provides market-leading solutions for digitizing and integrating all or part of the sourcing-to-pay or order-to-copper process, delivered on the world's largest retail network, to help you modernize and grow your business.",
		},
	],
};
