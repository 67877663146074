import React from "react";
import Footer from "../../components/global/Footer";
import imgSap from "../../css/assets/sap600.jpg";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const PorqueMiEmpresaNecesitaSAP = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "",
			text: "Por qué mi empresa necesita SAP",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="container lg:p-8 p-6 flex lg:flex-row flex-col items-center gap-10 mti-container">
				<div className="flex flex-col gap-4">
					{language !== "es-mx" ? (
						<>
							<h1 className="lg:text-4xl text-2xl">
								Why does my company need SAP?
							</h1>
							<em>B. Gudiño, 2022</em>
							<div className="flex flex-col gap-6 max-w-[830px] py-8">
								<p>
									<b>SAP Business One is an ERP solution whose </b>purpose is to
									manage the company by optimizing key processes, and
									centralizing efforts with instant access to real and complete
									information to obtain higher-value results and timely
									decision-making.
								</p>
								<p>
									An ERP system is a necessary economic investment for companies
									that wish to progress and excel in the market. It is an
									important strategic solution that should not be taken lightly.
									Although there are different options with different price
									levels, the best ERP to choose is the one that will add value
									to your business.
								</p>
								<p>
									Sudden business changes can happen externally, so companies
									need to be able to adapt to new business models.
								</p>
								<p>
									Having a management system that allows a business to stay
									current is a great advantage, not only to keep and get
									customers but also to prevail over the competition.
								</p>
								<p>
									<b>SAP Business One is an ERP solution that manages</b> the
									company by optimizing key processes and centralizing efforts
									with instant access to real and complete information to obtain
									higher-value results and timely decision-making.
								</p>
								<p>
									The vision of SAP Business One is aimed at enabling companies
									to meet their business objectives with technologies that allow
									them to change their business model and create new products
									and services in a digital economy.
								</p>
								<p>These are 3 great benefits of SAP:</p>
								<ol className="flex flex-col gap-4">
									<li>
										<p>
											<b>1. Gain a competitive advantage</b>
										</p>
										<p>
											With an ERP like SAP Business One you can allow the
											company to adapt to market changes and predict business
											trends. By having a technological tool that allows you to
											visualize all the processes in a single solution.
										</p>
									</li>
									<li>
										<p>
											<b>2. Integrates all commercial areas</b>
										</p>
										<p>
											Companies with centralized systems can focus on internal
											improvements, increasing productivity and development
											opportunities, and integration between areas helps their
											collaboration.
										</p>
									</li>
									<li>
										<p>
											<b>3. Availability of information</b>
										</p>
										<p>
											This tool allows you to obtain analysis and reports that
											help to better understand the business, the information of
											the company will be available at any time so that you can
											make decisions based on knowledge of data in real-time.
										</p>
									</li>
								</ol>
							</div>
						</>
					) : (
						<>
							<h1 className="lg:text-4xl text-2xl">
								¿Por qué mi empresa necesita SAP?
							</h1>
							<em>B. Gudiño, 2022</em>
							<div className="flex flex-col gap-6 max-w-[830px] py-8">
								<p>
									<b>SAP es una solución ERP</b> que tiene como propósito de
									gestionar la empresa optimizando los procesos claves,
									centralizado esfuerzos con acceso instantáneo a información
									real y completa para obtener resultados de mayor valor y la
									toma de decisiones oportuna.
								</p>
								<p>
									Un sistema ERP es una inversión económica necesaria para las
									empresas que deseen progresar y sobresalir en el mercado. Es
									una solución estratégica importante que no debe tomarse a la
									ligera, si bien existen diferentes opciones con distintos
									niveles de precios, el mejor ERP a elegir es aquel que va a
									aportar valor a tu negocio.
								</p>
								<p>
									Los cambios repentinos empresariales pueden darse de manera
									externa, por lo que las empresas deben poder adaptarse a
									nuevos modelos de negocios.
								</p>
								<p>
									Tener un sistema de gestión que permita a un negocio
									mantenerse actual es un gran punto a favor, no solo para
									conservar y conseguir clientes, pero también para prevalecer
									ante la competencia.
								</p>
								<p>
									La visión de <b>SAP</b> está dirigida a que las empresas
									puedan cumplir sus objetivos de negocio con tecnologías que
									permitan cambiar su modelo de negocio y crear nuevos productos
									y servicios en una economía digital.
								</p>
								<p>
									Estos son 3 grandes beneficios de <b>SAP:</b>
								</p>
								<ol className="flex flex-col gap-4">
									<li>
										<p>
											<b>1. Obtenga una ventaja competitiva</b>
										</p>
										<p>
											Con un ERP como SAP Business One podrá permitir que la
											empresa se adapte a los cambios del mercado y predecir las
											tendencias comerciales. Al contar con una herramienta
											tecnológica que te permita visualizar todos los procesos
											en una sola solución.
										</p>
									</li>
									<li>
										<p>
											<b>2. Integra todas las áreas comerciales</b>
										</p>
										<p>
											Las empresas con sistemas centralizados pueden enfocarse
											en mejoras internas, aumentando la productividad y las
											oportunidades de desarrollo, la integración entre áreas
											ayuda a la colaboración de estas.
										</p>
									</li>
									<li>
										<p>
											<b>3. Disponibilidad de información</b>
										</p>
										<p>
											Esta herramienta te permite obtener análisis e informes
											que ayudan a una mejor compresión del negocio, la
											información de la empresa estará disponible en cualquier
											momento para que pueda tomar decisiones basadas en
											conocimientos de datos en tiempo real.
										</p>
									</li>
								</ol>
							</div>
						</>
					)}
				</div>
				<div>
					<img src={imgSap} />
				</div>
			</div>
			<Footer />
		</>
	);
};
