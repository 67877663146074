import React from "react";
import mainImg from "../css/assets/quienes-somos.jpg";
import { useSelector } from "react-redux";

const WhoWeAre = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<>
			{/* <div className="relative items-start justify-center font-body grid lg:grid-cols-2 container who-we-are"> */}
			<div className="relative container who-we-are grid lg:grid-cols-2 font-body">
				<div className="flex items-center justify-center">
					<img
						className="lg:mt-[-120px] md:mt-[-60px] mt-[-30px]"
						src={mainImg}
						alt="Your Company"
					/>
				</div>
				<div className="px-8 flex flex-col mt-16">
					<div className="lg:text-5xl text-3xl tracking-[1px] font-bold">
						{language !== "es-mx" ? "About us?" : "¿Quiénes somos?"}
					</div>
					<div className="lg:text-xl text-base tracking-[1px] font-bold lg:mt-12 mt-8 flex flex-col gap-6">
						{language !== "es-mx" ? (
							<>
								<p>
									We are a consulting company specializing in SAP and business
									systems. With over 8 years of experience in the industry, we
									have helped our clients maximize their efficiency and drive
									their growth.
								</p>
								<p>
									Our team of experts is committed to providing personalized
									solutions and exceptional service to each of our clients.
								</p>
								<p>
									Our goal is to be a trusted partner and a valuable extension
									of your internal team, giving you peace of mind knowing that
									your systems are in good hands.
								</p>
							</>
						) : (
							<>
								<p>
									Somos una empresa de consultoría especializada en SAP y
									sistemas empresariales. Con más de 8 años de experiencia en la
									industria, hemos ayudado a nuestros clientes a maximizar su
									eficiencia y a impulsar su crecimiento.
								</p>
								<p>
									Nuestro equipo de expertos está comprometido a brindar
									soluciones personalizadas y a proporcionar un servicio
									excepcional a cada uno de nuestros clientes.
								</p>
								<p>
									Nuestro objetivo es ser un socio confiable y una extensión
									valiosa de su equipo interno, brindándoles la tranquilidad de
									saber que sus sistemas están en buenas manos.
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default WhoWeAre;
