import React from "react";
import { valuesData } from "../data/more-about-us";
import Slide from "react-reveal/Slide";
import { useSelector } from "react-redux";

const MisionVision = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<Slide left>
			<div className="bg-sky-500/[.05]">
				<div className="container mt-20 mb-16 lg:p-20 p-10 font-body">
					{valuesData &&
						valuesData.values.map((item, index) => (
							<div
								className={`${
									index > valuesData.values.length - 1 ? "mb-4" : "mb-0"
								} grid lg:grid-cols-12 items-center space-y-6 p-6`}
							>
								<div className="text-center lg:col-span-4">
									<figure className="w-full aspect-[3/1] flex items-center justify-center flex-grow-1">
										<img src={item.src} className="max-w-[128px]" alt="" />
									</figure>
									<h3 className="text-2xl mt-6 mb-6 font-bold">
										{language !== "es-mx" ? item.titleEng : item.title}
									</h3>
								</div>
								<div className="lg:text-lg text-base text-center lg:col-span-8 mt-2">
									<p className={`mx-auto font-bold tracking-[1px]`}>
										{language !== "es-mx" ? item.contentEng : item.content}
									</p>
								</div>
							</div>
						))}
				</div>
			</div>
		</Slide>
	);
};

export default MisionVision;
