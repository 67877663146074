import logo from "./logo.svg";
import "./App.css";
import { AppRouter } from "./router/AppRouter";

function App() {
	return (
		<div className="overflow-hidden">
			<AppRouter />
		</div>
	);
}

export default App;
