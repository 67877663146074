import React from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
	const { breadcrumbs } = props;
	const language = useSelector((state) => state.language.value);

	return (
		<div className="container">
			<div className="hidden lg:block">
				<div className="px-6 lg:h-[75px] flex items-center gap-3">
					{breadcrumbs.map((item) => {
						return (
							<>
								<Link
									className={
										item.href
											? "no-underline hover:underline font-body font-bold text-base lg:text-lg"
											: "no-underline font-body text-black text-base lg:text-lg"
									}
									to={item.href}
								>
									{language !== "es-mx" ? item.textEng : item.text}
								</Link>
								{item.href && <HiChevronRight />}
							</>
						);
					})}
				</div>
			</div>
			<div className="block lg:hidden">
				<div className="px-6 h-[50px] flex items-center gap-3 ">
					{breadcrumbs.map((item) => {
						if (item.href !== "" && item.href !== "/") {
							return (
								<>
									{item.href && <HiChevronLeft />}
									<Link
										className={
											item.href
												? "no-underline hover:underline font-body font-bold text-base lg:text-lg"
												: "no-underline font-body text-black text-base lg:text-lg"
										}
										to={item.href}
									>
										{language !== "es-mx" ? item.textEng : item.text}
									</Link>
								</>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
export default Breadcrumbs;
