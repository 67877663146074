import React from "react";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Footer from "../../components/global/Footer";
import educacion from "../../css/assets/industrias/educacion.jpg";
import Header from "../Header";
import { useSelector } from "react-redux";

export const Educacion = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "/",
			text: "Industrias",
		},
		{
			href: "",
			text: "Educacion",
		},
	];
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-center font-body mti-container">
				<img className="max-h-[800px]" src={educacion} alt="Your Company" />
				<div className="absolute flex items-start flex-col lg:mt-[400px] md:mt-[280px] mt-[120px] sm:px-8 px-4">
					<div className="text-[#F7F7F7] tracking-[1px] uppercase">
						{language !== "es-mx" ? "industries" : "industrias"}
					</div>
					<div className="text-[#F7F7F7] md:text-4xl text-2xl flex items-end flex-col tracking-[2.5px]">
						<span>{language !== "es-mx" ? "Education" : "Educacion"}</span>
					</div>
				</div>
			</div>
			<div className="font-body lg:mt-16 mt-8 container p-4">
				{language !== "es-mx" ? (
					<>
						<div className="font-bold text-lg mt-8 mb-20">
							MTi Consulting partners with higher education industry leaders to
							build a modern, agile, and sustainable operating foundation that
							is aligned with evolving business models, client needs, and
							socioeconomic pressures.
						</div>
						<div className="font-bold text-lg mt-8 mb-20">
							We help automate your processes by providing practical solutions
							that lead to cost reduction.
						</div>
						<div className="font-bold text-lg mt-8 mb-20">
							We ensure that your processes can work optimally by providing
							adequate technical support with a quick response to the problem.
						</div>
					</>
				) : (
					<>
						<div className="font-bold text-lg mt-8 mb-20">
							MTi Consulting se asocia con los líderes del sector de la
							educación superior para construir una base operativa moderna, ágil
							y sostenible que esté alineada con la evolución de los modelos
							comerciales, las necesidades de los clientes y las presiones
							socioeconómicas.
						</div>
						<div className="font-bold text-lg mt-8 mb-20">
							Ayudamos a la automatización de sus procesos brindando soluciones
							prácticas que llevan a la reducción de costos.
						</div>
						<div className="font-bold text-lg mt-8 mb-20">
							Damos la seguridad de que sus procesos pueden trabajar de una
							forma óptima brindando el soporte técnico adecuado con una
							respuesta rápida al problema.
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};
