import Articles from "../components/Articles";
import HeroImage from "../components/HeroImage";
import Industries from "../components/Industries";
import MisionVision from "../components/MisionVision";
import Services from "../components/Services";
import WhoWeAre from "../components/WhoWeAre";
import Footer from "../components/global/Footer";
import Header from "./Header";

const Home = () => {
	return (
		<>
			<Header />
			<HeroImage />
			<WhoWeAre />
			<MisionVision />
			<div className="container">
				<Services />
				<Articles />
				<Industries />
			</div>
			<Footer />
		</>
	);
};

export default Home;
