import React from "react";
import { servicesPageData } from "../data/services-page";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Services = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Fade bottom>
				<div className="tracking-[1px] text-center mt-12 font-body font-bold flex gap-6 flex-col">
					<h2 className="lg:text-4xl text-3xl">
						{language !== "es-mx"
							? "We solve your needs in SAP"
							: "Resolvemos tus necesidades en SAP"}
					</h2>
					{language !== "es-mx" ? (
						<p className="lg:text-lg text-base">
							From implementation and configuration to maintenance and support,
							we work hand in hand with our clients to make sure their systems
							are running optimally.
						</p>
					) : (
						<p className="lg:text-lg text-base">
							Desde la implementación y la configuración, hasta el mantenimiento
							y el soporte, trabajamos de la mano con nuestros clientes para
							asegurarnos de que sus sistemas estén funcionando de manera
							óptima.
						</p>
					)}
				</div>
				<div className="mt-12 mb-0 grid lg:grid-cols-3 md:grid-cols-2 md:grid-rows-2 text-center font-body">
					{servicesPageData &&
						servicesPageData.values.map((item, index) => (
							<div className="m-6 lg:m-8">
								<figure className="w-full aspect-[3/1] flex items-center justify-center flex-grow-1">
									<img src={item.src} className="max-w-[128px]" alt="" />
								</figure>
								<p
									className={`mx-auto font-bold tracking-[1px] max-w-[340px] text-xl`}
								>
									{language !== "es-mx" ? item.contentEng : item.content}
								</p>
							</div>
						))}
				</div>
				<div className="flex justify-center">
					<Link
						className="text-[#F7F7F7] bg-[#3991d2] py-3 px-6 mb-0 uppercase text-lg rounded-[6px] hover:bg-[#4597D3] tracking-[1px] hover:underline"
						to="/contacto"
					>
						{language !== "es-mx" ? "Contact Us" : "Contáctanos"}
					</Link>
				</div>
			</Fade>
		</>
	);
};

export default Services;
