import React, { useRef, useState } from "react";
import "../css/pages/Services.css";
import Footer from "../components/global/Footer";
import Header from "./Header";
import { useSelector } from "react-redux";

const AccordionItem = (props) => {
	const contentEl = useRef();
	const { handleToggle, active, faq } = props;
	const { header, id, list, headerEng, listEng } = faq;
	const language = useSelector((state) => state.language.value);

	return (
		<div className="rc-accordion-card">
			<div className="rc-accordion-header">
				<div
					className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
					onClick={() => handleToggle(id)}
				>
					<h5 className="rc-accordion-title">
						{language !== "es-mx" ? headerEng : header}
					</h5>
					<i className="fa fa-chevron-down rc-accordion-icon"></i>
				</div>
			</div>
			<div
				ref={contentEl}
				className={`rc-collapse ${active === id ? "show" : ""}`}
				style={
					active === id
						? { height: contentEl.current.scrollHeight }
						: { height: "0px" }
				}
			>
				<div className="rc-accordion-body">
					{language !== "es-mx" ? (
						<ul>
							{listEng.map((item, index) => {
								return <li>{item}</li>;
							})}
						</ul>
					) : (
						<ul>
							{list.map((item, index) => {
								return <li>{item}</li>;
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

const Services = () => {
	const faqs = [
		{
			id: 1,
			header: "1. Estrategia y planificación.",

			list: [
				"1.1 Definición y planificación de negocios de clase mundial",
				"1.2 Organización de alto rendimiento – definición de mejora de descubrimiento",
				"1.3 Planificación y seguimiento del rendimiento – KPI’s",
				"1.4 Evaluación y planificación CAPEX",
				"1.5 Gestión a la introducción de nuevos productos",
				"1.6 Gestión de personal y valuación de funciones",
				"1.7 Sistema de iniciativas de mejora de costes empresariales",
			],
			headerEng: "1. Strategy and planning.",
			listEng: [
				"1.1 Defining and planning world-class businesses",
				"1.2 High-performance organization – definition of discovery improvement",
				"1.3 Planning and performance monitoring – KPIs",
				"1.4 CAPEX assessment and planning",
				"1.5 Management of the introduction of new products",
				"1.6 Personnel Management and job evaluation",
				"1.7 System of business cost improvement initiatives",
			],
		},
		{
			id: 2,
			header: "2. Rendimiento de la operación.",
			list: [
				"2.1 Revisión, diseño e implementación del sistema de gestión",
				"2.2 Mejora de la productividad",
				"2.3 Operación Esbelta/ 6 SIGMAA – Cultura de erradicación de pérdidas",
				"2.4 Sistema de control diario",
				"2.5 Herramientas de mejora del rendimiento",
				"2.6 Estándares de gestión visual",
				"2.7 Hoja de ruta para el análisis y mejora de la brecha de competencias de personal",
			],
			headerEng: "2. Performance of the operation.",
			listEng: [
				"2.1 Review, design, and implementation of the management system",
				"2.2 Productivity improvement",
				"2.3 Lean Operation / 6 SIGMAA – Loss eradication culture",
				"2.4 Daily control system",
				"2.5 Performance improvement tools",
				"2.6 Visual management standards",
				"2.7 Roadmap for the Analysis and Improvement of the staff skills gap",
			],
		},
		{
			id: 3,
			header: "3. Cuidado de la salud.",
			list: [
				"3.1 Reducción de costos en operación de unidad",
				"3.2 Revisión y optimización del tiempo de flujo de pacientes",
				"3.3 Gestión de inventario (Planificación y control)",
				"3.4 De inventario (planificación y control)",
				"3.5 Rendimiento del sistema de mantenimiento y optimización de costes",
				"3.6 Revisión y optimización de rotación de camas",
			],
			headerEng: "3. Health care.",
			listEng: [
				"3.1 Cost reduction in unit operation",
				"3.2 Review and optimization of patient flow time",
				"3.3 Inventory management (Planning and control)",
				"3.4 Inventory (planning and control)",
				"3.5 Maintenance system performance and cost optimization",
				"3.6 Review and optimization of bed rotation",
			],
		},
		{
			id: 4,
			header: "4. Hotelería",
			list: [
				"4.1 Reducción de costos de operación de unidad",
				"4.2 Mejora general de la experiencia de los huéspedes",
				"4.3 Gestión de almacenes (planificación y control)",
				"4.4 Sistema de control diario",
				"4.5 Planificación de operaciones (capacidad, rendimiento, equipo)",
			],
			headerEng: "4. Hospitality",
			listEng: [
				"4.1 Reduction of unit operating costs",
				"4.2 General improvement of the guest experience",
				"4.3 Warehouse management (planning and control)",
				"4.4 Daily control system",
				"4.5 Operations planning (capacity, throughput, equipment)",
			],
		},
		{
			id: 5,
			header: "5. Herramientas comerciales",
			list: [
				"5.1 Servicio al cliente",
				"5.2 Penetración del mercado",
				"5.3 Estrategia de segmentación y cobertura de mercado",
				"5.4 Análisis y diseño del rendimiento del equipo",
				"5.5 Estrategia del portafolio de productos",
			],
			headerEng: "5. Trading tools",
			listEng: [
				"5.1 Customer service",
				"5.2 Market penetration",
				"5.3 Segmentation Strategy and market coverage",
				"5.4 Equipment performance analysis and Design",
				"5.5 Product portfolio strategy",
			],
		},
	];

	const faqsSoftware = [
		{
			id: 6,
			header: "Mantenimiento y soporte de software",
			list: [
				"Proporcionamos mantenimiento y soporte de software para garantizar que tu solución se mantenga actualizada y en pleno rendimiento. Nuestro equipo de expertos en soporte técnico está disponible para responder tus preguntas y ayudarte a resolver cualquier problema técnico que pueda surgir.",
			],
			headerEng: "Software maintenance and support",
			listEng: [
				"We provide software maintenance and support to ensure your solution stays up-to-date and up-to-date. Our team of technical support experts is available to answer your questions and help you resolve any technical issues that may arise.",
			],
		},
		{
			id: 7,
			header: "Integración de sistemas",
			list: [
				"Ofrecemos servicios de integración de sistemas para ayudarte a conectar tus aplicaciones y sistemas empresariales. Nuestro equipo de expertos en integración de sistemas trabaja para mejorar la eficiencia de tus procesos empresariales y garantizar que tus sistemas funcionen de manera armoniosa.",
			],
			headerEng: "Systems Integration",
			listEng: [
				"We offer system integration services to help you connect your business applications and systems. Our team of system integration experts works to improve the efficiency of your business processes and ensure that your systems work harmoniously.",
			],
		},
		{
			id: 8,
			header: "Consultoría en tecnología",
			list: [
				"Ofrecemos consultoría en tecnología para ayudarte a seleccionar las soluciones tecnológicas adecuadas para tus necesidades empresariales. Nuestro equipo de expertos en tecnología brinda recomendaciones basadas en las mejores prácticas y la experiencia en el mercado para garantizar que tomes las mejores decisiones de inversión tecnológica.",
			],
			headerEng: "Technology Consulting",
			listEng: [
				"We offer technology consulting to help you select the right technology solutions for your business needs. Our team of technology experts provides recommendations based on best practices and market experience to ensure you make the best technology investment decisions.",
			],
		},
		{
			id: 9,
			header: "Desarrollo de aplicaciones móviles",
			list: [
				"Desarrollamos aplicaciones móviles personalizadas para satisfacer las necesidades de tu negocio. Nuestro equipo de expertos en desarrollo de aplicaciones móviles crea soluciones para plataformas Android e iOS que se adaptan a tus necesidades y objetivos.",
			],
			headerEng: "Development of mobile applications",
			listEng: [
				"We develop customized mobile applications to meet the needs of your business. Our team of mobile application development experts creates solutions for Android and iOS platforms that adapt to your needs and objectives.",
			],
		},
	];

	const [active, setActive] = useState(null);

	const handleToggle = (index) => {
		if (active === index) {
			setActive(null);
		} else {
			setActive(index);
		}
	};

	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="container py-8 mti-container">
				<h1 className="lg:text-6xl text-3xl">
					{language !== "es-mx" ? "Services" : "Servicios"}
				</h1>
				<div className="flex flex-col justify-start pt-12 gap-5">
					<h2 className="lg:text-4xl text-xl text-[#3991d2]">
						{language !== "es-mx"
							? "Enterprise Solutions"
							: "Soluciones Empresariales"}
					</h2>
					{language !== "es-mx" ? (
						<p>
							We analyze and improve business operations to increase their
							growth and profitability. We offer innovative and flexible
							solutions focused on the management, planning, and productivity of
							the organization. Some business solutions found in our broad
							portfolio are:
						</p>
					) : (
						<p>
							Analizamos y mejoramos las operaciones de negocios para aumentar
							su crecimiento y rentabilidad. Ofrecemos soluciones innovadoras y
							flexibles se enfocan en la gestión, planificación y productividad
							de la organización. Algunas soluciones empresariales que se
							encuentran en nuestro amplio portafolio son:
						</p>
					)}
					<div className="py-8">
						{faqs.map((faq, index) => {
							return (
								<AccordionItem
									key={index}
									active={active}
									handleToggle={handleToggle}
									faq={faq}
								/>
							);
						})}
					</div>
					{language !== "es-mx" ? (
						<p>
							Our multidisciplinary team is results-oriented with deep
							improvement tools, knowledge, and experience in various
							industries. We also guarantee sustainable solutions for your
							business and assurance checks once the project is completed. Our
							goal is to produce
							<b> a minimum of 4:1 ROI </b>
							when our team is working for your business.
						</p>
					) : (
						<p>
							Nuestro equipo multidisciplinario está orientado a resultados con
							herramientas de mejora profunda, conocimientos y experiencia en
							varias industrias. Además garantizamos soluciones sostenibles para
							su negocio y comprobaciones de aseguramiento una vez que el
							proyecto esté concluido. Nuestro objetivo es producirle un
							<b> 4:1 ROI como mínimo </b>
							cuando nuestro equipo está trabajando para su negocio.
						</p>
					)}
				</div>
				<div className="flex flex-col pt-12 gap-5">
					{language !== "es-mx" ? (
						<>
							<h2 className="lg:text-4xl text-xl text-[#3991d2]">
								Software Factory
							</h2>
							<p>
								We offer custom software solutions to meet the specific needs of
								your business. Our team of software development experts works in
								collaboration with you to understand your requirements and
								provide customized solutions that fit your needs and goals.
							</p>
						</>
					) : (
						<>
							<h2 className="lg:text-4xl text-xl text-[#3991d2]">
								Fabrica de Software
							</h2>
							<p>
								Ofrecemos soluciones de software a medida para satisfacer las
								necesidades específicas de tu negocio. Nuestro equipo de
								expertos en desarrollo de software trabaja en colaboración
								contigo para entender tus requerimientos y proporcionar
								soluciones personalizadas que se ajusten a tus necesidades y
								objetivos.
							</p>
						</>
					)}
					<div className="py-8">
						{faqsSoftware.map((faq, index) => {
							return (
								<AccordionItem
									key={index}
									active={active}
									handleToggle={handleToggle}
									faq={faq}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Services;
