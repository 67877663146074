export const servicesData = {
	services: [
		{
			title: "s/4 hana",
			href: "/productos-sap/sap-s4-hana",
			target: "_self",
		},
		{
			title: "sap r3",
			href: "/productos-sap/sap-r3",
			target: "_self",
		},
		{
			title: "business one",
			href: "/productos-sap/sap-business-one",
			target: "_self",
		},
		{
			title: "success factors",
			href: "/productos-sap/success-factors",
			target: "_self",
		},
		{
			title: "ariba",
			href: "/productos-sap/sap-ariba",
			target: "_self",
		},
	],
};
