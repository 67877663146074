import React from "react";
import sapImg from "../css/assets/sap-productos.png";
import { productsSapData } from "../data/sap-products";
import Footer from "../components/global/Footer";
import { Link } from "react-router-dom";
import HeroSapPage from "../components/global/HeroSapPage";
import Header from "./Header";
import { useSelector } from "react-redux";

const Products = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="xl:hidden block">
				<HeroSapPage />
			</div>
			<div className="container mti-container">
				<div className="font-body flex xl:flex-row flex-col justify-center">
					<img
						className="my-28 xl:block hidden max-w-[40%]"
						src={sapImg}
						alt="Your Company"
					/>

					<div className=" bg-[#f5f5f5]">
						<div className="my-14 mx-12">
							{productsSapData &&
								productsSapData.products.map((item, index) => (
									<>
										<div className="md:text-5xl text-right text-3xl tracking-[1px] text-[#3991d2] w-auto">
											{item.title}
										</div>
										<div className="flex justify-end text-end lg:text-xl text-lg  tracking-[1px] uppercase mb-8 mt-2">
											<div className={`max-${item.subtitleSize}`}>
												{language !== "es-mx"
													? item.subtitleEng
													: item.subtitle}
											</div>
										</div>
										<div
											className={
												index === productsSapData.products.length - 1
													? "flex flex-col items-end text-right font-bold lg:text-lg text-base tracking-[1px]"
													: " flex flex-col items-end text-right font-bold lg:text-lg text-base tracking-[1px] mb-16"
											}
										>
											<div className="max-w-[460px]">
												{" "}
												{language !== "es-mx" ? item.contentEng : item.content}
											</div>

											<div className="mt-4">
												<Link
													to={item.href}
													className="lg:text-lg text-base underline underline-offset-2"
												>
													{language !== "es-mx" ? "Know More" : "Saber Más"}
												</Link>
											</div>
										</div>
									</>
								))}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Products;
