import automatizacion from "../css/assets/icons/automatizacion.png";
import soporte from "../css/assets/icons/soporte.png";
import outsourcing from "../css/assets/icons/outsourcing.png";
import soluciones from "../css/assets/icons/soluciones.png";
import procesos from "../css/assets/icons/procesos.png";
import cfdis from "../css/assets/icons/cfdis.png";

export const servicesPageData = {
	values: [
		{
			src: soporte,
			content: "Soporte de operación diaria del sistema",
			contentEng: "System daily operation support",
		},
		{
			src: outsourcing,
			content: "Outsourcing de consultores SAP en sitio remoto",
			contentEng: "Outsourcing of SAP consultants at a remote site",
		},
		{
			src: soluciones,
			content: "Desarrollo de soluciones localizadas",
			contentEng: "Development of localized solutions",
		},
		{
			src: procesos,
			content: "Mejora de procesos",
			contentEng: "Process improvement",
		},
		{
			src: automatizacion,
			content: "Automatización de procesos digitales",
			contentEng: "Automation of digital processes",
		},
		{
			src: cfdis,
			content: "Automatización digital de recepción CFDIs en un solo click",
			contentEng: "Digital automation of CFDIs reception in a single click",
		},
	],
};
