import React from "react";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Footer from "../../components/global/Footer";
import alimentaria from "../../css/assets/industrias/alimentaria.jpg";
import Header from "../Header";
import { useSelector } from "react-redux";

export const IndustriaAlimentaria = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
		},
		{
			href: "/",
			text: "Industrias",
		},
		{
			href: "",
			text: "Alimentaria",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-center font-body mti-container">
				<img className="max-h-[800px]" src={alimentaria} alt="Your Company" />
				<div className="absolute flex items-start flex-col lg:mt-[400px] md:mt-[280px] mt-[120px] sm:px-8 px-4">
					<div className="text-[#F7F7F7] tracking-[1px] uppercase text-base">
						{language !== "es-mx" ? "industries" : "industrias"}
					</div>
					<div className="text-[#F7F7F7] md:text-4xl text-2xl flex items-end flex-col tracking-[2.5px]">
						<span> {language !== "es-mx" ? "Food" : "Alimentaria"}</span>
					</div>
				</div>
			</div>
			<div className="font-body lg:mt-16 mt-8 container p-4">
				{language !== "es-mx" ? (
					<>
						<div className=" text-xl tracking-[1px]">
							Innovation in your processes
						</div>
						<div className="font-bold text-lg mt-8 mb-20 space-y-2">
							<p>
								In the food industry, it is more important than ever to be at
								the forefront of technological innovation.
							</p>
							<p>
								At MTi Consulting, we combine our industry insights with the
								power of digital transformation to help clients evolve into the
								future of industry and commerce.
							</p>
						</div>
						<div className=" text-xl tracking-[1px]">
							How to take advantage of this innovation?
						</div>
						<div>
							<ul className="font-bold text-lg mt-8 mb-20 space-y-6">
								<li>
									Leveraging industry practices throughout your life cycle
									processes to run successful operations.
								</li>
								<li>Automating processes that would bring cost reduction.</li>
								<li>
									Working collaboratively with other players through portals and
									applications.
								</li>
								<li>
									Developing future suppliers and trustworthy customers by
									offering complementary services.
								</li>
							</ul>
						</div>
					</>
				) : (
					<>
						<div className=" text-xl tracking-[1px]">
							Innovación en sus procesos
						</div>
						<div className="font-bold text-lg mt-8 mb-20 space-y-2">
							<p>
								En la industria alimentaria, es más importante que nunca estar a
								la vanguardia de la innovación tecnológica.
							</p>
							<p>
								En MTi Consulting, combinamos nuestros conocimientos de la
								industria con el poder de la transformación digital para ayudar
								a los clientes a evolucionar en el futuro del sector y el
								comercio.
							</p>
						</div>
						<div className=" text-xl tracking-[1px]">
							¿Cómo aprovechar esta innovación?
						</div>
						<div>
							<ul className="font-bold text-lg mt-8 mb-20 space-y-6">
								<li>
									Aprovechando prácticas de la industria a través de sus
									procesos de ciclo vital para ejecutar operaciones exitosas.
								</li>
								<li>
									Automatizando procesos que traerían consigo reducción de
									costos.
								</li>
								<li>
									Trabajando en colaboración con otros jugadores a través de
									portales y aplicaciones.
								</li>
								<li>
									Desarrollando futuros proveedores y clientes de confianza
									ofreciendo servicios complementarios.
								</li>
							</ul>
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
};
