import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Products from "../pages/Products";
import { SapS4Hana } from "../pages/productsPages/SapS4Hana";
import { SapR3 } from "../pages/productsPages/SapR3";
import { SapBusinessOne } from "../pages/productsPages/SapBusinessOne";
import { SuccessFactors } from "../pages/productsPages/SuccessFactors";
import { SapAriba } from "../pages/productsPages/SapAriba";
import Industries from "../components/Industries";
import { IndustriaAlimentaria } from "../pages/industrias/IndustriaAlimentaria";
import { IndustriaAutomotriz } from "../pages/industrias/IndustriaAutomotriz";
import { Educacion } from "../pages/industrias/Educacion";
import { Hotelera } from "../pages/industrias/Hotelera";
import { Manufactura } from "../pages/industrias/Manufactura";
import Services from "../pages/Services";
import Contacto from "../pages/Contact";
import { PorqueMiEmpresaNecesitaSAP } from "../pages/articulos/PorqueMiEmpresaNecesitaSAP";
import { ProductoresDeAgave } from "../pages/articulos/ProductoresDeAgave";
import { CloudComputing } from "../pages/articulos/CloudComputing";

export const AppRouter = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/productos-sap" element={<Products />} />
				<Route path="productos-sap/sap-s4-hana" element={<SapS4Hana />} />
				<Route path="productos-sap/sap-r3" element={<SapR3 />} />
				<Route
					path="productos-sap/sap-business-one"
					element={<SapBusinessOne />}
				/>
				<Route
					path="productos-sap/success-factors"
					element={<SuccessFactors />}
				/>
				<Route path="productos-sap/sap-ariba" element={<SapAriba />} />

				<Route path="industrias" element={<Industries />} />

				<Route
					path="industrias/alimentaria"
					element={<IndustriaAlimentaria />}
				/>
				<Route path="industrias/automotriz" element={<IndustriaAutomotriz />} />
				<Route path="industrias/educacion" element={<Educacion />} />
				<Route path="industrias/hotelera" element={<Hotelera />} />
				<Route path="industrias/manufactura" element={<Manufactura />} />
				<Route path="servicios" element={<Services />} />
				<Route path="contacto" element={<Contacto />} />

				<Route
					path="articulos/porque-mi-empresa-necesita-sap"
					element={<PorqueMiEmpresaNecesitaSAP />}
				/>
				<Route
					path="articulos/productores-de-agave"
					element={<ProductoresDeAgave />}
				/>
				<Route path="articulos/cloud-computing" element={<CloudComputing />} />
			</Routes>
		</>
	);
};
