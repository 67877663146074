import actualidad from "../css/assets/industrias/manofactura/mano1.jpg";
import futuro from "../css/assets/industrias/manofactura/mano2.jpg";
import retos from "../css/assets/industrias/manofactura/mano3.jpg";

export const manufacturingData = {
	values: [
		{
			src: actualidad,
			title: "Actualidad",
			content:
				"La industria de la manufactura ha evolucionado en la última década, transformándose en una entidad más compleja, digitalizada y en constante cambio.",
			imgOnLeft: true,
			titleEng: "Present",
			contentEng:
				"The manufacturing industry has evolved in the last decade, becoming a more complex, digitized, and constantly changing entity.",
		},
		{
			src: futuro,
			title: "Futuro del sector",
			content:
				"El futuro de la manufactura ha tenido un efecto directo en las industrias, requiriéndoles volver a pensar su nuevo desarrollo de producto y condensar sus procesos de cadena de suministro. Ayudamos a nuestros clientes a combinar innovación tecnológica y eficiencia de procesos para optimizar el rendimiento y proporcionar nuevo valor para sus clientes. ",
			imgOnLeft: false,
			titleEng: "Future of the sector",
			contentEng:
				"The future of manufacturing has had a direct effect on industries, requiring them to rethink their new product development and condense their supply chain processes. We help our clients combine technological innovation and process efficiencies to optimize performance and deliver new value for their customers.",
		},
		{
			src: retos,
			title: "Retos actuales",
			content:
				"Los desafíos del sector incluyen sistemas obsoletos, plataformas incompatibles, aplicaciones y conjuntos de datos, y procesos ineficientes y de trabajo intensivo que frenan la competitividad. MTi Consulting ayuda a las organizaciones de manufactura  a impulsar su transformación digital y permitir una colaboración esencial entre los equipos operativos y de TI. Mediante la optimización de plataformas, aplicaciones, procesos y conjuntos de funcionalidades, conectamos plataformas dispares e integramos los sistemas administrativos y de piso.",
			imgOnLeft: true,
			titleEng: "Current challenges",
			contentEng:
				"Industry challenges include outdated systems, incompatible platforms, applications, and data sets, and labor-intensive and inefficient processes that hold back competitiveness. MTi Consulting helps manufacturing organizations drive their digital transformation and enable essential collaboration between IT and operational teams. By optimizing platforms, applications, processes, and feature sets, we connect disparate platforms and integrate back-office and back-office systems.",
		},
	],
};
