import React from "react";
import { articlesData } from "../data/articles";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Articles = () => {
	const language = useSelector((state) => state.language.value);

	return (
		<>
			<Slide right>
				<div className="p-6">
					<h2 className="lg:text-5xl text-4xl tracking-[1px] text-left mt-12 font-body font-bold">
						{language !== "es-mx" ? "Articles" : "Articulos"}
					</h2>
					<div className="lg:mt-16 mt-8 lg:mb-16 mb-0 grid lg:grid-cols-3 font-body gap-8">
						{articlesData.articlesItem.length > 0 &&
							articlesData.articlesItem.map((item) => (
								<div className="border border-solid border-gray-30 bg-gray-10 shadow-[2px_2px_6px_rgba(0,0,0,0.1)] flex flex-col">
									<div className="article-img">
										<figure className="w-full">
											<img src={item.src} className="block w-full" />
										</figure>
									</div>
									<div className="px-6 mt-6 mb-6 lg:mt-6 lg:mb-6 space-y-6 flex flex-col flex-grow ">
										<h3 className="text-xl font-bold h-[60px]">
											{language !== "es-mx" ? item.titleEng : item.title}
										</h3>
										<p className="text-base lg:text-md lg:min-h-[168px] xl:min-h-[120px]">
											{language !== "es-mx" ? item.contentEng : item.content}
										</p>
										<Link
											to={item.button.href}
											className="text-[#F7F7F7] bg-[#3991d2] py-3 px-6 uppercase text-lg rounded-[6px] hover:bg-[#4597D3] tracking-[1px] hover:underline hover:text-[#F7F7F7] w-fit"
										>
											{language !== "es-mx"
												? item.button.textEng
												: item.button.text}
										</Link>
									</div>
								</div>
							))}
					</div>
				</div>
			</Slide>
		</>
	);
};

export default Articles;
