import React from "react";
import Footer from "../../components/global/Footer";
import HeroSapPage from "../../components/global/HeroSapPage";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";
export const SapBusinessOne = () => {
	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
			textEng: "Home",
		},
		{
			href: "/productos-sap",
			text: "Productos SAP",
			textEng: "SAP Products",
		},
		{
			href: "",
			text: "SAP Business One",
			textEng: "SAP Business One",
		},
	];

	const language = useSelector((state) => state.language.value);

	return (
		<div>
			<Header />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<HeroSapPage />
			<div className="container mti-container">
				<div className="font-body px-6">
					<div className="text-4xl lg:text-6xl tracking-[1px] text-[#3991d2] py-6 lg:py-10">
						{/* {title} */}
						SAP Business One
					</div>
					<div
						className={
							"flex flex-col text-base lg:text-lg tracking-[1px] mb-16"
						}
					>
						<div className="w-auto space-y-8 lg:space-y-10">
							{language !== "es-mx" ? (
								<>
									<p>
										It is a business management software solution designed
										specifically for small and medium-sized businesses. It
										offers a wide range of business functions, including
										finance, accounting, customer relationship management (CRM),
										sales and supply management, inventory, and production
										management, among others. The solution is designed to be
										easy to use and scalable and can be customized and tailored
										to the business’s specific needs.
									</p>
									<p>
										<b>Why does my company need SAP Business One?</b>
									</p>
									<p>
										By using SAP Business One, companies can integrate and
										automate their business processes, improve visibility into
										their information, and make more informed decisions. In
										addition, by having a centralized view of the company's
										data, it is possible to detect opportunities for improvement
										and optimization and respond more quickly to market needs.
										In short, a company can use SAP Business One to improve its
										efficiency, agility, and decision-making capabilities.
									</p>
								</>
							) : (
								<>
									<p>
										Es una solución de software de gestión empresarial diseñada
										específicamente para pequeñas y medianas empresas. Ofrece
										una amplia gama de funciones de negocios, incluyendo
										finanzas, contabilidad, gestión de relaciones con los
										clientes (CRM), gestión de ventas y suministro, gestión de
										inventario y producción, entre otras. La solución está
										diseñada para ser fácil de usar y escalable, y puede ser
										personalizada y adaptada a las necesidades específicas de la
										empresa.
									</p>
									<p>
										<b>¿Por qué mi empresa necesita SAP business one?</b>
									</p>
									<p>
										Al utilizar SAP Business One, las empresas pueden integrar y
										automatizar sus procesos de negocio, mejorar la visibilidad
										de su información y tomar decisiones más informadas. Además,
										al tener una vista centralizada de los datos de la empresa,
										es posible detectar oportunidades de mejora y optimización y
										responder más rápidamente a las necesidades del mercado. En
										resumen, una empresa puede utilizar SAP Business One para
										mejorar su eficiencia, agilidad y capacidad de tomar
										decisiones.
									</p>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="mb-16">
					<div className="lg:w-[966px] lg:h-[544px] m-auto">
						<iframe
							class="w-full aspect-video"
							src="https://www.youtube.com/embed/ry3-drbeg_8"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
