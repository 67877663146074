import articulo1 from "../css/assets/articulo1.jpg";
import articulo2 from "../css/assets/agave-sap.webp";
import articulo3 from "../css/assets/cloud-computing.png";

export const articlesData = {
	articlesItem: [
		{
			title: "¿Por qué mi empresa necesita SAP?",
			content:
				"SAP Business One es una solución ERP que tiene como propósito gestionar la empresa optimizando los procesos clave. ",
			button: {
				href: "/articulos/porque-mi-empresa-necesita-sap",
				text: "Mostar más",
				textEng: "Show more",
				target: "_blank",
			},
			src: articulo1,
			titleEng: "Why does my company need SAP?",
			contentEng:
				"SAP Business One is an ERP solution that manages the company by optimizing key processes.",
		},
		{
			title: "Productores de agave se apoyan de tecnologías de SAP",
			content:
				"Compañías como Inulina y Miel Agave (IMAG) y Mieles Campos Azules, se posicionaron en la industria de procesamiento de agave tras implementar la solución SAP Business One.",
			button: {
				href: "/articulos/productores-de-agave",
				text: "Mostar más",
				textEng: "Show more",
				target: "_blank",
			},
			src: articulo2,
			titleEng: "Agave producers rely on SAP technologies",
			contentEng:
				"Companies such as Inulina y Miel Agave (IMAG) and Mieles Campos Azules, positioned themselves in the agave processing industry after implementing the SAP Business One solution.",
		},
		{
			title: "Cloud Computing: Bienvenidos al futuro",
			content:
				"Esta tecnología evita que las empresas tengan que encargarse de aprovisionar, configurar o gestionar los recursos y permite que paguen únicamente por los que usen.",
			button: {
				href: "/articulos/cloud-computing",
				text: "Mostar más",
				textEng: "Show more",
				target: "_blank",
			},
			src: articulo3,
			titleEng: "Cloud Computing: Welcome to the Future",
			contentEng:
				"This technology frees companies from having to take care of provisioning, configuring, or managing resources and allows them to pay only for what they use.",
		},
	],
};
