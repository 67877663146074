import React from "react";
import Footer from "../../components/global/Footer";
import HeroSapPage from "../../components/global/HeroSapPage";
import Breadcrumbs from "../../components/global/Breadcrumbs";
import Header from "../Header";
import { useSelector } from "react-redux";

export const SapS4Hana = () => {
	const language = useSelector((state) => state.language.value);

	let breadcrumbs = [
		{
			href: "/",
			text: "Inicio",
			textEng: "Home",
		},
		{
			href: "/productos-sap",
			text: "Productos SAP",
			textEng: "SAP Products",
		},
		{
			href: "",
			text: "SAP S/4 HANA",
			textEng: "SAP S/4 HANA",
		},
	];
	return (
		<div>
			<Header />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<HeroSapPage />
			<div className="container mti-container">
				<div className="font-body px-6">
					<div className="text-4xl lg:text-6xl tracking-[1px] text-[#3991d2] py-6 lg:py-10">
						SAP S/4 HANA
					</div>
					<div
						className={
							"flex flex-col text-base lg:text-lg tracking-[1px] mb-16"
						}
					>
						<div className="w-auto space-y-8 lg:space-y-10">
							{language !== "es-mx" ? (
								<>
									<p>
										It is a business management platform (ERP) from the SAP
										company. It is a state-of-the-art solution that offers a
										modern user experience and an advanced technology platform
										that allows companies to digitize their processes and
										improve the efficiency of their operations. SAP S/4 HANA
										combines the functionality of SAP ERP with cutting-edge
										technologies such as artificial intelligence, machine
										learning, and real-time analytics to provide complete,
										real-time visibility into the business.
									</p>
									<p>
										<b>Why does my company need SAP S/4 HANA?</b>
									</p>
									<p>
										SAP allows companies to automate and optimize their business
										processes, improve efficiency, visibility, and control, and
										make decisions in real-time.
									</p>
								</>
							) : (
								<>
									<p>
										Es una plataforma de gestión empresarial (ERP) de la
										compañía SAP. Es una solución de última generación que
										ofrece una experiencia de usuario moderna y una plataforma
										de tecnología avanzada que permite a las empresas
										digitalizar sus procesos y mejorar la eficiencia de sus
										operaciones. SAP S/4HANA combina la funcionalidad de SAP ERP
										con tecnologías de vanguardia como inteligencia artificial,
										machine learning y análisis en tiempo real para proporcionar
										una visibilidad completa y en tiempo real de la empresa.
									</p>
									<p>
										<b>¿Por qué mi empresa necesita SAP S/4 HANA?</b>
									</p>
									<p>
										SAP permite a las empresas automatizar y optimizar sus
										procesos de negocio, mejorar la eficiencia, visibilidad y
										control, y tomar decisiones informadas en tiempo real.
									</p>
								</>
							)}

							<ul className="w-auto space-y-4 lg:space-y-6">
								{language !== "es-mx" ? (
									<>
										<li>
											<b>Improve efficiency and productivity: </b>SAP enables
											you to integrate and automate key business processes,
											reducing time and costs and improving efficiency.
										</li>
										<li>
											<b>Visibility and control:</b>SAP offers real-time
											visibility and complete control over business processes,
											enabling companies to make informed decisions and improve
											decision-making.
										</li>
										<li>
											<b>Systems integration: </b>SAP allows you to integrate
											and connect different systems and applications, which
											improves efficiency and reduces errors.
										</li>
										<li>
											<b>Improve customer satisfaction: </b>SAP helps companies
											improve the quality of their services and products, which
											in turn improves customer satisfaction.
										</li>
										<li>
											<b>Improves competitiveness: </b>By automating and
											optimizing their business processes, companies can improve
											their competitiveness and market positioning.
										</li>
									</>
								) : (
									<>
										<li>
											<b>Mejora de la eficiencia y productividad:</b> SAP
											permite integrar y automatizar procesos empresariales
											clave, lo que reduce el tiempo y los costos y mejora la
											eficiencia.
										</li>
										<li>
											<b>Visibilidad y control:</b> SAP ofrece una visibilidad
											en tiempo real y un control completo sobre los procesos
											empresariales, lo que permite a las empresas tomar
											decisiones informadas y mejorar la toma de decisiones.
										</li>
										<li>
											<b>Integración de sistemas:</b> SAP permite integrar y
											conectar diferentes sistemas y aplicaciones, lo que mejora
											la eficiencia y reduce los errores.
										</li>
										<li>
											<b>Mejora de la satisfacción del cliente:</b> SAP ayuda a
											las empresas a mejorar la calidad de sus servicios y
											productos, lo que a su vez mejora la satisfacción del
											cliente.
										</li>
										<li>
											<b>Mejora de la competitividad:</b> Al automatizar y
											optimizar sus procesos empresariales, las empresas pueden
											mejorar su competitividad y posicionamiento en el mercado.
										</li>
									</>
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className="mb-16">
					<div className="lg:w-[966px] lg:h-[544px] m-auto">
						<iframe
							class="w-full aspect-video"
							src="https://www.youtube.com/embed/odWJuYbNk6M"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
